import Vuex, { createStore, Payload, Store } from "vuex";
import TeleconsultationsModule from "./modules/teleconsultation";

import UsersModule from "./modules/auth";
import PatientsModule from "./modules/patient";
import AllergieModule from "./modules/allergie";
import EtablissementModule from "./modules/etablissement";
import AnamneseModule from "./modules/amnanese";
import ExamenComplementaireModule from "./modules/examen";
import MotifModule from "./motif";
import TypesTeleconsultationModule from "./modules/typesTeleconsultation";
import VuexPersistence from "vuex-persist";
import AlerteModules from "./modules/alerte";
import NotificationModules from "./modules/notification";
import ExamenClinicModule from "./modules/examen_clinic";
import OrdonnanceModule from "./modules/ordonance/indext";
import BigblueButtonModules from "./modules/bigBlueButton";

const vuexCookie = new VuexPersistence({
  modules: [
    "users",
    "patient",
    "notifications",
    "teleconsultations",
    "mettings",
  ], //only save user module
});

export default createStore({
  modules: {
    users: UsersModule,
    teleconsultations: TeleconsultationsModule,
    notifications: NotificationModules,
    mettings: BigblueButtonModules,
    patient: PatientsModule,
    allergie: AllergieModule,
    etablissement: EtablissementModule,
    anamnese: AnamneseModule,
    examen: ExamenComplementaireModule,
    examen_clinics: ExamenClinicModule,
    motif: MotifModule,
    types: TypesTeleconsultationModule,
    alertes: AlerteModules,
    ordonnances: OrdonnanceModule,
  },
  plugins: [vuexCookie.plugin],
});
