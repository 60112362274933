<template lang="">
    <div class="row">
        <div class="row bg-white rounded shadow px-4 pb-5 mt-4 pt-1">
            <div class="d-flex justify-content-between mt-4 mb-1">
                <a @click="$router.go(-1)" class="back"><i class="fa fa-arrow-left cursor-pointer" aria-hidden="true"></i></a>
                <h5 class="fwb default">Details du Bon de Prise en Charge BPC #{{ bonPriseEnCharge?.id }}</h5>
                <!-- <a  target="_blank"><i class="fa-solid fa-circle-play mx-1 cursor-pointer fa-2xl"></i></a> v-if="teleconsultation?.url" :href="teleconsultation?.url" -->
                <a :href="bonPriseEnCharge?.pdf" v-can="'Imprimer un bon de prise en charge'" target="_Blank" type="button" class="btn text-primary fwb" 
                >
                <i class="fa fa-print ml-2"></i> Imprimer</a> <!-- @click="printBonPriseEnCharge(bonPriseEnCharge?.uuid)" -->
            </div>
            <div class="d-flex justify-content-end" v-if="bonPriseEnCharge?.patient?.user">
                <div class="p-2" v-can="'Partager un bon de prise en charge'">
                    Envoyer par
                </div>
                <!-- <div class="p-2">
                    <ShareNetwork
                        network="email"
                        :url="bonPriseEnCharge?.pdf"
                        :title="'Hello, consulter le  bon de prise en charge *BPC #'+bonPriseEnCharge?.id+'* du patient *'+bonPriseEnCharge?.patient.user.name+'*'"
                        description="Cliquez sur le lien ci-dessus">
                    </ShareNetwork>
                </div> -->

                <div class="p-2" v-can="'Partager un bon de prise en charge'">
                    <i @click="open = true" class="fa-solid fa-envelope fa-2x" style="color: #172b4d; cursor: pointer;"></i>
                    <DynamicModal :show="open" @close="open = false" title="Envoyer par email">
                        <div>
                            <b-form @submit="onSubmit">
                                <b-form-group
                                    id="input-group-1"
                                    label="Email(s):"
                                    label-for="input-1"
                                    description="vous pouvez entrer plusieurs emails séparer par une virgule (,)">
                                    <b-form-input
                                    id="input-1"
                                    v-model="state.email"
                                    required
                                    type="text"
                                    placeholder="resultat@medicasure.com, medecin@medicasure.com"></b-form-input>
                                    <li v-if="v$.$error && v$.email.$invalid" class="red list-none ">
                                        {{ "vérifier que le(s) email(s) est(sont) bien renseigné(s)" }} <br>
                                    </li>
                                </b-form-group>

                                <b-form-group id="input-group-2" label="Message:" label-for="input-2">
                                    <b-form-textarea
                                        id="textarea-rows"
                                        v-model="state.message"
                                        placeholder="Entrer le message"
                                        rows="4"
                                        required
                                    ></b-form-textarea>
                                    <li v-if="v$.$error && v$.message.$invalid" class="red list-none ">
                                        {{ "renseigner le message a transmettre" }} <br>
                                    </li>
                                </b-form-group>
                                <div class="container" v-if="loading">
                                    <div class="container my-3 d-flex justify-content-center">
                                        <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin"/>
                                    </div>
                                    <br />
                                </div>
                                <button v-else type="submit" class="btn btn-primary"><i class="fa fa-share-alt"></i> Partager</button>
                            </b-form>
                        </div>
                    </DynamicModal>
                </div>
                <div class="p-2" v-can="'Partager un bon de prise en charge'">
                    <ShareNetwork
                        network="telegram"
                        :url="bonPriseEnCharge?.pdf"
                        :title="'Hello, consulter le  bon de prise en charge *BPC #'+bonPriseEnCharge?.id+'* du patient *'+bonPriseEnCharge?.patient.user.name+'*'"
                        description="Cliquez sur le lien ci-dessus">
                        <i class="fa-brands fa-telegram fa-2x" style="color: #0088cc;"></i>
                    </ShareNetwork>
                </div>
                <div class="p-2" v-can="'Partager un bon de prise en charge'">
                    <ShareNetwork
                        network="whatsapp"
                        :url="bonPriseEnCharge?.pdf"
                        :title="'Hello, consulter le  bon de prise en charge *BPC #'+bonPriseEnCharge?.id+'* du patient *'+bonPriseEnCharge?.patient.user.name+'*'"
                        description="Cliquez sur le lien ci-dessus">
                        <i class="fa-brands fa-whatsapp fa-2x" style="color: #128C7E;"></i>
                    </ShareNetwork>
                </div>
            </div>
            <div v-if="loading" class="table-responsive">
                <div class="container">
                    <div class="container my-3 d-flex justify-content-center">
                        <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin"/>
                    </div>
                    <br />
                </div>
            </div>
            <div v-else>
                <div class="mt-3">
                    <fieldset class="form-group border p-2">
                        <legend class="default float-none w-auto px-2 h2">Documentations</legend>
                            <div class="boxDetails w-100">
                                <div class="row pt-2">
                                    <div class="col-md-6">
                                        <p class="mb-4">
                                            <span class=" default fwb my-2"><img src="@/assets/icons/DetailTeleconsultation/medical-records.png" alt="" class="img-alert"/></span> <span class="default fwb">Evénement Médical associé : </span> <span class="pt-1">{{bonPriseEnCharge?.ligne_temps?.motif?.description}}</span>
                                        </p>
                                        <p>
                                            <div>
                                                <span class=" default fwb mb-1 mt-2"><img src="@/assets/icons/DetailTeleconsultation/save-money.png" alt="" class="img-alert"/></span> <span class="default fwb">Option de financement : </span>
                                            </div>
                                            <div class="mx-3">
                                                <div class="mt-2 d-inline-flex" v-for="finance in bonPriseEnCharge?.option_financements">
                                                    <span class="bgDetails py-1 px-2 mx-1">
                                                        {{finance.libelle}}
                                                    </span>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-md-6">
                                        <p class="mb-4">
                                            <span class=" default fwb m-1 my-2"><img src="@/assets/icons/DetailTeleconsultation/medical-service.png" alt="" class="img-alert"/></span>  <span class="default fwb">Niveau d'urgence : </span>
                                            <span class="mt-1 mx-2">{{bonPriseEnCharge?.niveau_urgence?.id}}</span>
                                        </p>
                                        <p>
                                            <div>
                                                <span class=" default fwb mb-1 mt-2"><img src="@/assets/icons/DetailTeleconsultation/prescription.png" alt="" class="img-alert"/> </span> <span class="default fwb">Raison de la Prescription : </span>
                                                <span class="pt-1 pl-3" v-for="raison in bonPriseEnCharge?.raison_prescriptions">{{raison.libelle}}</span>
                                            </div>
                                        </p>
                                    </div>
                                </div>
                            </div>
                    </fieldset>
                </div>

                <div class="mt-5">
                    <fieldset class="form-group border p-2">
                        <legend class="default float-none w-auto px-2 h2">Données Variables</legend>
                            <div class="boxDetails w-100">
                                <div class="row">
                                    <div class="col-md-6" v-if="bonPriseEnCharge?.type_teleconsultations">
                                        <div class="mb-3"> <span class=" default fwb"><img src="@/assets/icons/DetailTeleconsultation/examination.png" alt="" class="img-alert"/></span><span class="default fwb">Type téléconsultation : </span>
                                            <span>
                                                {{ bonPriseEnCharge?.type_teleconsultations[0].libelle }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-3"><span class=" default fwb"><img src="@/assets/icons/DetailTeleconsultation/examination.png" alt="" class="img-alert"/></span><span class="default fwb">Plainte : </span>
                                            <span class="mt-2" v-html="bonPriseEnCharge?.plainte"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6" v-if="bonPriseEnCharge?.type_examens?.length > 0">
                                        <div class="mb-3"><span class=" default fwb"><img src="@/assets/icons/DetailTeleconsultation/examination.png" alt="" class="img-alert"/></span><span class="default fwb">Examens à réaliser</span>
                                            <div class="mt-2">
                                                <ol>
                                                    <li v-for="(type_examen, index) in bonPriseEnCharge?.type_examens" :key="index"><span class="fwb">{{ type_examen.libelle }}</span>
                                                        <ul>
                                                            <li v-for="(examen_complementaire, index) in type_examen.examen_complementaires" :key="index">{{ examen_complementaire.fr_description }}</li>
                                                        </ul>
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-3"  v-if="bonPriseEnCharge?.rendez_vous?.length > 0">
                                            <span class=" default fwb"><img src="@/assets/icons/DetailTeleconsultation/examination.png" alt="" class="img-alert"/></span><span class="default fwb">Rendez-vous</span>
                                            <div class="mt-2">
                                                <span class="default fwb ml-3">Motif : </span> <span v-html="bonPriseEnCharge?.rendez_vous[0].motifs"></span>
                                                <span class="default fwb ml-3">Date et heure : </span> <span> {{ bonPriseEnCharge?.rendez_vous[0].date }}</span>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <span class=" default fwb"><img src="@/assets/icons/DetailTeleconsultation/examination.png" alt="" class="img-alert"/></span><span class="default fwb">Bulletin d’examens / Ordonnance Disponible :</span>
                                            <div class="mt-2">
                                            </div>
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-md-6 mb-3" v-for="(item, index) in bonPriseEnCharge?.examens_analyses" :key="item.uuid"> 
                                                        AB #{{ item.id }} 
                                                        <span class="bg-white "> 
                                                            <a class="btn btn-primary shadow" :href="item.pdf" target="_blank"><i class="fa-solid fa-link"></i>&nbsp;Voir</a>
                                                        </span>
                                                    </div>
                                                    <div class="col-md-6 mb-3" v-for="(item, index) in bonPriseEnCharge?.ordonnances" :key="'ordo'+item.uuid"> 
                                                        RX #{{ item.id }}
                                                        <span class="bg-white "> 
                                                            <a class="btn btn-primary shadow" :href="item.pdf" target="_blank"><i class="fa-solid fa-link"></i>&nbsp;Voir</a>
                                                        </span>
                                                    </div>
                                                    <div class="col-md-6 mb-3" v-for="(item, index) in bonPriseEnCharge?.examens_imageries" :key="'ordo'+item.uuid"> 
                                                        IM #{{ item.id }}
                                                        <span class="bg-white "> 
                                                            <a class="btn btn-primary shadow" :href="item.pdf" target="_blank"><i class="fa-solid fa-link"></i>&nbsp;Voir</a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </fieldset>
                </div>
            </div>
            <div v-for="doctor in bonPriseEnCharge?.medecin" class="my-3">
                <span class="my-3">
                    {{doctor.name}}
                </span>
                <div>
                    <span class="">
                        Téléphone : 
                        <span class="">
                            {{doctor.user?.telephone}}
                        </span>
                    </span>
                </div>
                <div>
                    <span class="">
                        Numero d'ordre : 
                        <span class="">
                            {{doctor.numero_ordre}}
                        </span>
                    </span>
                </div>
                <div v-if="doctor?.user?.signature">
                   <img :src="doctor?.user?.signature" class="img-fluid w-25"/>
                </div>
            </div>
            <div class="d-md-flex justify-content-md-end" v-can="'Modifier un bon de prise en charge'">
                <div class="mb-2 mt-2 mx-2" v-can="'Modifier un bon de prise en charge'">
                    <button @click="openShow(bonPriseEnCharge)" size="sm" class="btn btn-dark px-2 fw-bold text-h5 w-100">
                        <i class="fa fa-edit px-1"></i><span class="px-1">Modifier</span>
                    </button>
                </div>
                <div class="mb-2 mt-2 mx-2" v-can="'Supprimer un bon de prise en charge'">
                    <button  @click="deleteBon(bonPriseEnCharge?.id)" size="sm" class="btn btn-outline-danger px-2 fw-bold text-h5 w-100">
                        <i class="fa fa-trash px-1"></i><span class="px-1">Supprimer</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>
<script setup>
// import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { usePriseEnCharge } from "@/_storeV2/priseEnCharge";
import moment from "moment";
import { computed, onMounted, reactive } from 'vue';
import UserInfo from '@/components/organisms/UserInfo.vue';
import ExamenComplementaireModule from '../../../store/modules/examen/index';
import AddPrescription from '@/views/Admin/ePrescription/AddPrescription.vue';
import { inject, ref } from 'vue';
import DynamicModal from '@/components/organisms/DynamicModal.vue'

import { useVuelidate } from '@vuelidate/core'
import { useTextareaAutosize } from "@vueuse/core";

import { required } from '@vuelidate/validators'
const swal = inject('$swal');
const route = useRouter();
const router = useRoute();
const store = usePriseEnCharge();

const loading = computed(() => store.getLoading);
const bonPriseEnCharge = computed(() => store.getPriseEnCharge);

// Globale
const props = defineProps({
    bonPriseEnCharge: {},
})

const emailsValid = (value) => {
    const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailArray = value.split(',').map(email => email.trim());
    return emailArray.every(email => emailRegExp.test(email));
}

const rules = {
    email: { required, emailsValid},
    message: { required }
}

const state = reactive({
    email: '',
    message: ''
});

const v$ = useVuelidate(rules, state)

const open = ref(false)

const deleteBon=(item_id)=>{
        swal.fire({
        title: 'Voulez-vous vraiment supprimer ce Bulletin?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Annuler',
        denyButtonText: `Supprimer`,
    })
    .then((result) => {
        if (result.isDenied) {
            store.deleteBon(item_id).then(() => {
                route.push({
                    name: 'listing-bon-de-prise-en-charge',
                })
                store.fetchBonPriseEnCharge(1, "", 5); 
            });
        }
    })
    }
// const paginations = computed(() => store.getPriseEnCharges);

function openShow(bon) {
    route.push({
        name: 'edit-bon-de-prise-en-charge',
        params: {'uuid': bon.uuid },
    })
}

const onSubmit = event => {
    v$.value.$validate()
    const result = v$.value.$error
    if (!result) {

        store.shareBonPriseEnCharge(router.params.uuid, state).then(() => {
            open.value = false
        });
    }
}

onMounted(() => {
    // console.log(route.params?.id );
    store.getUniqueBonPriseEnCharge(router.params.uuid);
})

</script>