<template lang="" >
    <!-- <loader-data :loading="loading"/> -->
    <div class="notification-list">
        <h3 class="default fwb">Listing des alertes</h3><br>
        <div class="my-3 d-flex justify-content-center" v-if="loading">
            <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin"/>
        </div>
        <div class="mt-4" v-if="paginations">
            <div>
                <ul class="list-none p-0 d-md-flex align-items-center gap-2 float-md-end">
                    <div class="d-flex align-items-center justify-content-between gap-1">
                        <!-- <li class=""><button class="btn p-0 text-primary fwb">Tous</button></li>
                        <li class=""><button class="btn p-0 text-primary fwb">Non lus</button></li> -->
                        <li class="">
                            <select class="form-select" v-model="statut_id" aria-label="Default select example" @change="changeStatut($event)">
                                <option value="">Tout</option>
                                <option v-for="(statut, index) in statuts" :key="'alerte'+index" :value="statut.id">{{ statut.valeur }}</option>
                            </select>
                        </li>
                    </div>
                    <li class="mt-2 mt-md-0"><input type="text" class="form-control" v-model="search" @keyup="searchAlerte($event)" placeholder="rechercher une plainte ou un patient"></li>
                </ul>
            </div><br>
            <!-- composante de listing des alertes -->
            <table-list :alertes="paginations.data" :uuid="route.query.uuid" v-if="!loading"/>
            <nav aria-label="Page navigation example" v-if="!loading">
                <ul class="pagination pagination-sm justify-content-center">
                    <li :class="`page-item ${paginations.current_page == paginations.from ? 'disabled' : ''}`"><a class="page-link" href="#" @click="previous(paginations.current_page)" tabindex="-1" aria-disabled="true">Previous</a></li>
                    <li :class="`page-item ${paginations.current_page == n ? 'active' : ''}`" v-for="n in paginations.last_page" @click="changePage(n)"><a class="page-link" href="#">{{ n }}</a></li>
                    <li class="page-item">
                        <li :class="`page-item ${paginations.current_page == paginations.last_page ? 'disabled' : ''}`"><a class="page-link" href="#" @click="next(paginations.current_page)" tabindex="-1" aria-disabled="true">Next</a></li>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>
<script setup>
import LoaderData from '@/components/molecules/LoaderData.vue'
import TableList from "@/components/organisms/TableList.vue";
import Modal from '../Teleconsultation/Modal.vue';
import { ref, computed, onMounted } from "vue";
import { useRoute } from 'vue-router'
import { useStore } from 'vuex';
import RechercherPatient from '../../../components/molecules/RecherchePatient.vue';
import { useTextareaAutosize } from "@vueuse/core";

//Methods for Modals
const store = useStore()
const currentPage = ref(1);
const rowsPerPage = ref(5);
const page_size = ref(10);
const search = ref("");
const statut_id = ref("");

const route = useRoute();
const dialogVisible = ref(false);
const paginations = computed(() => store.state.alertes.paginations)
const loading = computed(() => store.state.alertes.loadingStatus)
const statuts = computed(() => store.state.alertes.statuts)
const { textarea, input } = useTextareaAutosize()

const launchDemoModal = () => {
  dialogVisible.value = true
}

const changePage = page => {
    store.dispatch("alertes/fetchAlertes", { page: page, page_size: page_size.value, search: search.value, statut_id: statut_id.value});
}

const previous = page => {
    page--;
    store.dispatch("alertes/fetchAlertes", { page: page, page_size: page_size.value, search: search.value, statut_id: statut_id.value });
}

const next = page => {
    page++;
    store.dispatch("alertes/fetchAlertes", { page: page, page_size: page_size.value, search: search.value, statut_id: statut_id.value });
}

const changeSize = () => {
    store.dispatch("alertes/fetchAlertes", { page: 1, page_size: page_size.value, search: search.value, statut_id: statut_id.value });
}

const searchAlerte = event => {
    if(event.target.value.length >2){
        store.dispatch("alertes/fetchAlertes", { page: 1, page_size: page_size.value, search: event.target.value, statut_id: statut_id.value });
    }else if(event.target.value.length == 0){
        store.dispatch("alertes/fetchAlertes", { page: 1, page_size: page_size.value, search: "", statut_id: statut_id.value });
    }
    search.value = event.target.value;
}

const changeStatut = event => {
    store.dispatch("alertes/fetchAlertes", { page: 1, page_size: page_size.value, search: search.value, statut_id: event.target.value });
    statut_id.value = event.target.value;
}


onMounted(() => {
    store.dispatch("alertes/fetchAlertes");
    store.dispatch("alertes/fetchStatuts");
})
</script>