import { Alerte } from "@/_types/alerte";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { Pagination } from "@/_types";
import AlerteService from "@/_services/AlerteService";

@Module({
  namespaced: true,
})
export default class AlerteModules extends VuexModule {
  alertes: Array<Alerte> = [];
  alerte: Alerte | null = null;
  statuts: Array<any> = [];
  paginations: Pagination | null = null;

  loadingStatus: boolean = true;

  get g_alertes() {
    return this.paginations?.data.data;
  }
  get g_alerte() {
    return this.alerte;
  }
  get gpaginations() {
    return this.paginations;
  }

  //getters loading
  get gloadingStatus() {
    return this.loadingStatus;
  }

  get gstatuts() {
    return this.statuts;
  }

  //-------------------------- Mutation teleconsultation
  @Mutation
  getAlertesHandler(paginations: Pagination) {
    this.paginations = paginations;
  }

  @Mutation
  getAlerteHandler(alerte: Alerte) {
    this.alerte = alerte;
  }

  @Mutation
  deleteAlerteHandler(alerte: Alerte) {
    this.alertes = this.alertes.filter((item) => item.id != alerte.id);
  }

  @Mutation
  mloadingStatus(newStatus: boolean) {
    this.loadingStatus = newStatus;
  }

  @Mutation
  statutsHandler(statuts: Array<any>) {
    this.statuts = statuts;
  }

  //---------------------------------------- Actions Alerte
  @Action
  fetchAlertes(
    paginate = { page: 1, page_size: 10, search: "", statut_id: "" }
  ) {
    this.context.commit("mloadingStatus", true);

    AlerteService.getAlertes(
      paginate.page,
      paginate.page_size,
      paginate.search,
      paginate.statut_id
    )
      .then((res: Pagination) => {
        this.context.commit("getAlertesHandler", res.data);
        this.context.commit("mloadingStatus", false);
      })
      .catch((error: any) => {
        console.log("error ", error.response.data);
        this.context.commit("mloadingStatus", false);
      });
  }

  @Action
  fetchAlerte(id: number) {
    AlerteService.getAlerte(id)
      .then((res: any) => {
        let alerte_notifications = this.context.rootState.notifications.alertes;
        if (alerte_notifications.find((item: any) => item.id == id)) {
          this.context.dispatch("notifications/deleteAlertes", id, {
            root: true,
          });
        }
        this.context.commit("getAlerteHandler", res.data);
      })
      .catch((error: any) => {
        console.log("error ", error);
      });
  }

  @Action
  fetchStatuts() {
    this.context.commit("mloadingStatus", true);

    AlerteService.getStatuts()
      .then((res: any) => {
        this.context.commit("statutsHandler", res.data?.data.slice(0, 4));
        this.context.commit("mloadingStatus", false);
      })
      .catch((error: any) => {
        console.log("error ", error.response.data);
      });
  }
}
