import Axios from '../api';
import { Pagination } from '@/_types/pagination';
import { Allergie } from '@/_types/allergie';


class AllergieService 
{

    path: string;

    constructor() {
        this.path = "/allergies";
    }

    async getAllergies(page = 1): Promise<Pagination> {
        return await Axios.get(`${this.path}?page=${page}`)
    }

    async updatedAllergie(id: number, allergie: Allergie): Promise<Pagination> {
        return await Axios.post(`${this.path}/${id}`, allergie)
    }

    async createdAllergie(allergie: Allergie | any): Promise<Pagination> {
        return await Axios.post(`${this.path}`, allergie)
    }

    // async deleteAllergie(id: number): Promise<void> {
    //     return await Axios.delete(`${this.path}/${id}`)
    // }

    async deleteAllergie(relation_id: Number, allergie_id: Number, relation: string): Promise<void> {
        return await Axios.delete(`${this.path}/${relation_id}/${allergie_id}/${relation}`)
    }

    async searchAllergie(search : string | undefined, page = 1): Promise<Pagination> {
        return await Axios.get(`${this.path}?page=${page}&search=${search}`);
    }
}

export default new AllergieService();