<template lang="" >
   <!--  <div class="container">
        <div class="col-md-12 mt-5">
            <h2 class="default fwb">Liste des Notifications</h2><br>
            <div>
                <ul class="list-none p-0 d-md-flex align-items-center gap-2 float-md-end">
                    <div class="d-flex align-items-center justify-content-between gap-1">
                        <li class=""><button class="btn p-0 text-primary fwb">Tous</button></li>
                        <li class=""><button class="btn p-0 text-primary fwb">Non lus</button></li>
                        <li class="">
                            <select class="form-select" aria-label="Default select example">
                                <option selected>En cours</option>
                                <option value="1">Annuler</option>
                                <option value="2">Terminer</option>
                            </select>
                        </li>
                    </div>
                    <li class="mt-2 mt-md-0"><input type="text" class="form-control" placeholder="rechercher..."></li>
                </ul>
            </div><br>
        </div>
    </div> -->
    <div class="container">
        <div class="row">
            <div class="my-3 d-flex justify-content-center" v-if="loading">
                <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin"/>
            </div>
            <div class="table-responsive" v-if="!loading">
                <table class="table table-striped">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">type</th>
                        <th scope="col">descriptions</th>
                        <th scope="col">Date</th>
                        <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(notification, index) in paginations?.data" :key="'notification'+notification">
                            <th scope="row">{{ index+1 }}</th>
                            <td>{{ notification.type?.includes("AlerteNotification") ? "Alerte" : notification.type }}</td>
                            <td>
                                <ul>
                                    <li>Patient: {{ notification.data.patient.name }}</li>
                                    <li>Plainte: {{ notification.data.plainte }}</li>
                                </ul>
                            </td>
                            <td>{{ moment(notification.created_at).format("YYYY-MM-DD HH:mm") }}</td>
                            <td>
                                <b-button @click="voir(notification)" class="text-primary">voir</b-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <nav aria-label="Page navigation example" v-if="paginations?.last_page > 1">
                    <ul class="pagination pagination-sm justify-content-center">
                        <li :class="`page-item ${paginations.current_page == paginations.from ? 'disabled' : ''}`"><a class="page-link" href="#" @click="previous(paginations.current_page)" tabindex="-1" aria-disabled="true">Previous</a></li>
                        <li :class="`page-item ${paginations.current_page == n ? 'active' : ''}`" v-for="n in paginations.last_page" @click="changePage(n)"><a class="page-link" href="#">{{ n }}</a></li>
                        <li class="page-item">
                            <li :class="`page-item ${paginations.current_page == paginations.last_page ? 'disabled' : ''}`"><a class="page-link" href="#" @click="next(paginations.current_page)" tabindex="-1" aria-disabled="true">Next</a></li>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>
<script setup>
import TableList from "@/components/organisms/TableList.vue";
import Modal from '../Teleconsultation/Modal.vue';
import { ref, computed, onMounted } from "vue";
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'
import RechercherPatient from '../../../components/molecules/RecherchePatient.vue';
import { useTextareaAutosize } from "@vueuse/core";
import moment from "moment";


//Methods for Modals
const dialogVisible = ref(false);

//Methods for Modals
const store = useStore()
const currentPage = ref(1);
const rowsPerPage = ref(5);
const page_size = ref(10);

const router = useRouter();


const paginations = computed(() => store.state.notifications.paginations)
const loading = computed(() => store.state.notifications.loadingStatus)

const { textarea, input } = useTextareaAutosize()
//Call option modal
function launchDemoModal() { 
    dialogVisible.value = true 
}

const changePage = page => {
    store.dispatch("notifications/fetchNotifications", { page: page, page_size: page_size.value });
}

const previous = page => {
    page--;
    store.dispatch("notifications/fetchNotifications", { page: page, page_size: page_size.value });
}

const next = page => {
    page++;
    store.dispatch("notifications/fetchNotifications", { page: page, page_size: page_size.value });
}

const changeSize = () => {
    store.dispatch("notifications/fetchNotifications", { page: 1, page_size: page_size.value });
}

const voir = notification => {
    router.push({
        name: 'alertes',
        query: {'uuid': notification.data.uuid},
    })
}


onMounted(() => {
    store.dispatch("notifications/fetchNotifications")
})
</script>