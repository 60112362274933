<template lang="">
    <div class="d-flex justify-content-center gap-1 w-100">
        <a href="#" @click="OpenMetting(urlModerator)"  class="circle-button btn b-video bg-primary shadow text-white"><i class="fa fa-video-camera fa-2x"></i></a>
        <!-- <a href="#" @click="OpenPhoneLink(telephone)" class="circle-button btn bg-default shadow" variant="primary" size="sm"><i class="text-white fa fa-phone fa-2x"></i> </a> -->
    </div>
</template>
<script setup>

import { ref, toRefs, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore();
const props = defineProps({telephone: '', urlModerator: ''})
const emit = defineEmits(['gotoTeleconsultationForm'])
const { telephone, urlModerator } = toRefs(props)

const OpenPhoneLink = phone => {
    // store.dispatch("mettings/seturl");
    window.open(`tel:${phone}`);
    emit('gotoTeleconsultationForm')
}
const OpenMetting = urlModerator => {
    // store.dispatch("mettings/seturl");
    window.open(`${urlModerator}`, '_blank');
    emit('gotoTeleconsultationForm')
}
</script>