import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { Pagination } from "@/_types"

import motifService from "@/_services/motifService";
import { useToast } from 'vue-toast-notification';

@Module({
    namespaced: true
})

export default class MotifModule extends VuexModule
{
    motifs: Pagination | null = null;

    $toast = useToast();

    loadingStatus: boolean = false;

    get gMotifs(){
        return this.motifs
    }

    //getters loading
    get gloadingStatus(){
        return this.loadingStatus
    }

    @Mutation
    setMotifs(motifs: Pagination){
        this.motifs = motifs;
    }

    @Mutation
    mloadingStatus(newStatus: boolean){
        this.loadingStatus = newStatus
    }


    @Action({ rawError: true })
    fetchMotifs(page = 1){
        this.context.commit('mloadingStatus', true)
        motifService.getMotifTeleconsultations(page)
        .then((res: Pagination) => {
            this.context.commit("setMotifs", res.data);
            this.context.commit('mloadingStatus', false)
        })
        .catch((error : any) => {
             console.log("error motif",error);
        });
    }

    @Action({ rawError: true })
    searchMotif(element = {search: "", page:  1}){
        this.context.commit('mloadingStatus', true)
        motifService.searchMotif(element.search)
        .then((res: Pagination) => {
            this.context.commit("setMotifs", res.data);
            this.context.commit('mloadingStatus', false)
        })
        .catch((error : any) => {
             console.log("error motif",error);
        });
    }

    @Action
    async createdMotif(motif: any) {
        this.context.commit('mloadingStatus', true)
        const motifs = {
            motifs: motif.motif.map((item: any) =>  item.id != null ? item.id : "item__"+item.description),
            teleconsultation_id: motif.teleconsultation_id
        }

        await motifService.createMotif(motifs).then((res: any) =>{
            this.context.commit('mloadingStatus', false)
            let teleconsultation = {...this.context.rootState.teleconsultations.teleconsultation, motifs: res.data.data};
            this.context.commit("teleconsultations/getTeleconsultationHandler", teleconsultation, { root: true });
            this.$toast.success('Motif crée avec succès', { position: "top-right" })
        }, err => {
            console.log("error", err)
        })
    }

    @Action
    async deleteMotif(motif = {relation_id: 0, motif_id: 0, relation: ""}) {
        this.context.commit('mloadingStatus', true)
        await motifService.deleteMotif(motif.relation_id, motif.motif_id, motif.relation).then((res: any) =>{
            this.context.commit('mloadingStatus', false)
            let motifs = this.context.rootState.teleconsultations.teleconsultation.motifs.filter((item: any) => item.id != res.data.data.id);
            let teleconsultation = {...this.context.rootState.teleconsultations.teleconsultation, motifs: motifs};
            this.context.commit("teleconsultations/getTeleconsultationHandler", teleconsultation, { root: true });
            this.$toast.success('Motif supprimé avec succès', { position: "top-right" })
        }, err => {
            console.log("error", err)
            this.context.commit('mloadingStatus', false)
        })
    }
}