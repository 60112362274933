<template lang="">
    <div class="row">
        <div class="col-md-12 default">
            <p>
                <span class="fw-bold m-1">Type:</span>{{ teleconsultation.type?.libelle }}
            </p>
            <p>
                <span class="fw-bold m-1">Etablissement:</span>{{ teleconsultation.etablissements[0]?.name }}
            </p>
            <p>
                <span class="fw-bold m-1">Date de téléconsultation:</span>{{ moment(teleconsultation.date_heure).format("YYYY-MM-DD HH:mm") }}
            </p>
            <p>
                <span class="fw-bold m-1">Conduite à tenir:</span> <span v-html="teleconsultation.cat"></span> 
            </p>
            <p v-if="teleconsultation?.url">
               <span>Revoir la téléconsultation: </span> <a :href="teleconsultation?.url" target="_blank"><i class="fa-solid fa-circle-play mx-1 cursor-pointer fa-2xl"></i></a> 
            </p>
         <!--    <p>
                <span class="fw-bold m-1">Description du diagnostic:</span> <span v-html="teleconsultation.description_diagnostic"></span> 
            </p> -->
        </div>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-9" v-if="docState == 'edited'">
                    <Transition name="slide-up">
                        <div class="" >
                            <VueMultiselect v-model="state.motif" tag-placeholder="Ajouter un motif" :multiple="true" :close-on-select="false" :options="motifs" placeholder="Sélectionner ou saissiser un motif" label="description" track-by="id" :taggable="true" @tag="addTag" @search-change="asyncFindMotifPrincipale" />
                            <li v-if="v$.motif.$error" class="red list-none ">
                                {{ "evotre motif" }}
                            </li>
                        </div>
                    </Transition>
                </div>
                <div class="col-md">
                    <div class="btn-anim">
                        <Transition name="slide-up">
                            <button v-if="docState === 'saved'"
                                @click="docState = 'edited'" class="btn default border d-flex align-items-center gap-1 mt-1 py-2"><i class="fa-solid fa-plus"></i> Ajouter un motif</button>
                            <button v-else-if="docState === 'edited'"
                                @click="docState = 'saved', addMotif()" class="btn border-0 shadow text-white d-flex align-items-center bg-primary
                                justify-content-center gap-1 mt-1 py-2"><i class="fa-solid fa-bookmark"></i><span>Enregistrer</span></button>
                        </Transition>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table caption-top default">
                    <caption>Liste des motifs</caption>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Description</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <div class="position-absolute motif start-50" v-if="loader">
                        <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin"/>
                    </div>
                    <tbody v-if="!loader">
                        <tr v-for="(motif, index) in teleconsultation.motifs" :key="`motif${index}`">
                            <td>{{ index+1 }}</td>
                            <td>{{ motif.description }}</td>
                            <td class="d-flex">
                                <span class="rounded-circle border p-2 cursor-pointer btn-delete" @click="deleteMotifs(teleconsultation.id, motif.id, 'teleconsultations')"><i class="fa-solid fa-trash red"></i></span>
                               <!--  <span class="p-2"><i class="fa-solid fa-pen-to-square"></i></span> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, toRefs, computed, onMounted, reactive, inject } from 'vue';
import moment from 'moment';
import VueMultiselect from 'vue-multiselect';
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useStore } from 'vuex';

const props = defineProps({
  teleconsultation: {},
})

const store = useStore();
const { teleconsultation } = toRefs(props)
const docState = ref('saved')

const state = reactive({
    motif: [],
    teleconsultation_id: props.teleconsultation.id
})

const rules ={
    motif: {
        required: required
    }
}

const v$ = useVuelidate(rules, state)

const addMotif = () => {
    v$.value.$validate()
    if(!v$.value.$error){
        store.dispatch("motif/createdMotif", state);
        if(state.motif.length > 0) {
            state.motif = []
        }
    }else{
        //
    }
}
const motif_id = computed(() => {
    return store.state.motif.motifs?.data.data.map(motif => motif.id)
})

let motifs = computed(() => store.state.motif.motifs?.data.data)
const addTag = newTag => {
    const tag = {
        id: null,
        uuid: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
        description: newTag
    };
    motifs.value.push(tag)
    state.motif.push(tag)
}
const swal = inject('$swal');
const deleteMotifs = (relation_id, motif_id, relation) => {
    swal.fire({
        title: 'Voulez-vous vraiment supprimer ce motif',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Annuler',
        denyButtonText: `Supprimer`,
    }).then((result) => {
        if (result.isDenied) {
            store.dispatch('motif/deleteMotif', {relation_id: relation_id, motif_id: motif_id, relation: relation});
        } else if (result.isConfirmed) {
            swal.fire('Changes are not saved', '', 'info')
        }
    })
}

const asyncFindMotifPrincipale = (query) => {
    //state.isLoadingMotifPrincipale = true
    if(query.length <=3){
        store.dispatch("motif/searchMotif", {search: query, page:  1}).then(() => {
            //state.isLoadingMotifPrincipale = false;
        });
    }

}

const loader = computed(() => store.state.motif.loadingStatus)

onMounted(() => {
    store.dispatch("motif/fetchMotifs")
})
</script>