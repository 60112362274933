<template lang="">
    <div class="row">
        <div class="col-md-12">
            <Create-Examen-Clinique :teleconsultation_id="teleconsultation.id"/>
            <div class="table-responsive">
                <table class="table caption-top">
                    <caption>Liste des examens clinique</caption>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Description</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <div class="position-absolute top-50 start-50" v-if="loader">
                        <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin"/>
                    </div>
                    <tbody v-if="!loader">
                        <tr v-for="(examen_clinique, index) in teleconsultation.examen_cliniques" :key="`examen_clinique${index}`">
                            <td>{{ index+1 }}</td>
                            <td>{{ examen_clinique?.fr_description }}</td>
                            <td class="d-flex">
                                <span class="rounded-circle border p-2 cursor-pointer btn-delete" @click="deleteExamen(teleconsultation.id, examen_clinique.id, 'teleconsultations')"><i class="fa-solid fa-trash red"></i></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script setup>
import CreateExamenClinique from './CreateExamenClinique.vue';
import { ref, toRefs, computed, onMounted, inject } from 'vue';
import moment from 'moment';
import VueMultiselect from 'vue-multiselect';

import { useStore } from 'vuex'
const store = useStore();

const props = defineProps({
  teleconsultation: {},
})

const docState = ref('saved')
const anamnese = ref([])
const examen = ref([])
const { teleconsultation } = toRefs(props)

const swal = inject('$swal');
const deleteExamen = (relation_id, examen_clinic_id, relation) => {
    swal.fire({
        title: 'Voulez-vous vraiment supprimer cet Examen?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Annuler',
        denyButtonText: `Supprimer`,
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isDenied) {
            store.dispatch('examen_clinics/deleteExamenClinique', {relation_id: relation_id, examen_clinic_id: examen_clinic_id, relation: relation}).then(() => {
                // swal.fire('Saved!', '', 'success');
            })
        } else if (result.isConfirmed) {
            swal.fire('Changes are not saved', '', 'info')
        }
    })
}

const loader = computed(() => store.state.examen_clinics.loadingStatus)
</script>