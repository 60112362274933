/**
 * Importation des modules nécessaire
 */
import { ENV } from "./env";
import axios, { AxiosResponse } from "axios";
import accountService from "@/_services/accountService";
import router from "@/router";
import store from "@/store";

import { useToast } from 'vue-toast-notification';

const $toast = useToast();

const Axios = axios.create({
    baseURL : ENV.apiMedsurlink,
    headers: {
        "Content-type": "application/json",
    },
});

// const AxiosSignature = axios.create({
//     baseURL: 'http://localhost:8001/api/',
//     headers: {
//         "Content-type": "application/json",
//     },
// });

/**
 * interceptor pour injection token
 */
 Axios.interceptors.request.use((request: any) => {

    //Si connectee on ajoute le token dans l'entete
    if(accountService.isLogged()){
        request.headers.Authorization = 'Bearer '+accountService.getToken();
    }
    // console.log(request);

    return request
})

Axios.interceptors.response.use((response : AxiosResponse<any, any>) => {
    return response
}, error => {
    console.log("error",error.message)

    if(!error.response){
        // Erreur réseau
        $toast.error(error.message, { position: "top-right" })
        store.commit('users/mloadingLogin', false)
        localStorage.clear()
        return Promise.reject(error)
    }else{
        if(error.response.status == 401){
            accountService.logout()
            // $toast.error(error.response.data.message, { position: "top-right" })
            store.commit('users/mloadingLogin', false)
            router.push('/login')
        }else{
            // Erreur de l'API
            // store.commit('notifications/displayAlert', {alertResponse: true, alertMessage: error.response.statusText})
            // $toast.error(error.message, { position: "top-right" })
            store.commit('users/mloadingLogin', false)
            // localStorage.clear();
            return Promise.reject(error)
        }
    }
})

export default Axios;