<template lang="">
    <div v-if="typesTeleconsultation?.length > 0">
        <label class="typo__label">Type d'antécédent<span class="red">*</span></label>
        <VueMultiselect v-model="state.type" deselect-label="Can't remove this value" track-by="id" label="libelle" placeholder="Sélectionneer le type" :options="typesTeleconsultation" :searchable="false" :allow-empty="false">
            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.type }}</strong></template>
        </VueMultiselect>
        <li v-if="v$.type.$error" class="red list-none ">
            {{ "sélectionner un type d'antécédent" }}
        </li>
    </div>
    <div class="mt-3">
        <label class="typo__label">Date début<span class="red">*</span></label>
        <Datepicker v-model="state.date" placeholder="sélectionner une date"/>
        <li v-if="v$.date.$error" class="red list-none">
            {{ "entrer une date" }}
        </li>
    </div>
    <div class="mt-3">
        <label class="typo__label">Description<span class="red">*</span></label>
        <Tiny :height="300" :state="state" :v$="v$"/>
    </div>
</template>
<script setup>
import VueMultiselect from 'vue-multiselect';
import Datepicker from '@vuepic/vue-datepicker';
import Tiny from '@/components/organisms/Tiny.vue';

import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
    state: {},
    v$: {}
})

const store = useStore();
const newAnt = ref(false);

const typesTeleconsultation = computed(() => store.state.types.typesTeleconsultation)

onMounted(() => {
    store.dispatch("types/fetchTypesTeleconsultation", "antecedents");
})
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>