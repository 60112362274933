<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { Modal } from 'bootstrap'

const props = defineProps<{
  modelValue: boolean
  title: string,
  colorTitle: "#fff" ,
  bgcolor: "#007bff",
  width: string,
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: boolean): void
}>()

const modalRef = ref<HTMLElement | null>(null)
let modal: Modal
onMounted(() => {
  if (modalRef.value) {
    modal = new Modal(modalRef.value)
  }
})

watch(() => props.modelValue, (modelValue) => {
  if (modelValue) {
    modal.show()
  } else {
    modal.hide()
  }
})

function close() {
  
  emit('update:modelValue', false)

}
function open() {
  emit('update:modelValue', true)
}
</script>

<template>
  <div class="modal fade" tabindex="-1" aria-hidden="true" ref="modalRef" role="dialog" @click.self="close">
    <div :class="'modal-dialog modal-'+width" role="document" @click.prevent.self="close">
      <div class="modal-content">
        <div class="modal-header" :style="{'background-color': bgcolor, 'color': colorTitle}">
          <h5 class="modal-title px-2">{{ title }}</h5>
          <button type="button" class="btn-close btn-close-white mx-2" id="fermeture" aria-label="Close" @click="close"></button>
        </div>
        <div class="modal-body">
          <slot />
        </div>
        <div class="modal-footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>