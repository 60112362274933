<template lang="">
    <div>
        <button @click="modalVisible = true"
            type="button" class="btn btn-primary p-2 fw-bold teleconsultation-btn">
            <i class="fa-solid fa-plus"></i> {{ btn_text }}
        </button>
        <!-- Modal to display the call option -->
        <Modal v-model="modalVisible" title="Sélectionner un patient" bgcolor="#172b4d" colorTitle="white" notification="true" width="lg">
            <div>
                <div>
                    <label class="typo__label default">Rechercher un Patient</label>
                    <div class="w-100 d-block d-md-flex align-items-center" v-if="datas">
                        <VueMultiselect 
                            v-model="find" 
                            :options="searchData" 
                            :preserve-search="true" 
                            @search-change="DataPatient" 
                            @select="confirmPatient(find)"
                            placeholder="Rechercher un Patient" 
                            label="name" :preselect-first="false" >
                        </VueMultiselect>
                    </div>
                    <div v-if="find.user_id">
                        <p class="pb-2 mt-4 default">Informations sur le patient selectionné: </p>
                        <div class="container default">
                            <div class="row">
                                <div class="col">
                                    <p><i class="fa-solid fa-user text-secondary mx-1"></i>Nom: <strong>{{ find.nom }}</strong></p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <p><i class="fa-solid fa-user text-secondary mx-1"></i>Prenom: <strong>{{ find.prenom }}</strong></p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <p><i class="fa-solid fa-address-book text-secondary mx-1"></i>Adresse: <strong>{{ find.user_id }}</strong></p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <p><i class="fa-solid fa-address-book text-secondary mx-1"></i>Téléphone: <a href="#" @click="OpenPhoneLink(find.telephone)"><strong>{{ find.telephone }}</strong></a></p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <p><i class="fa-solid fa-calendar-days text-secondary mx-1"></i>Date de Naissance:
                                    <strong>{{ find.date_de_naissance }}</strong></p>
                                </div>
                            </div>
                            <div class="row border-bottom-dashed">
                                <div class="col">
                                    <p><i class="fa-solid fa-person text-secondary mx-1"></i>Sexe: <strong>{{ find.sexe }}</strong></p>
                                </div>
                                <div class="col">
                                    <p><i class="fa-solid fa-calendar-days text-secondary mx-1"></i> Age: <strong>{{ find.age }}</strong></p>
                                </div>
                            </div>
                            <!-- <div @click="(patientSelect = true)" class="form-check p-0 cursor-pointer my-2" v-if="!patientSelect">
                                <i :class="'primary'" class="fa-regular fa-square-check"></i>
                                <label class="form-check-label mx-2 cursor-pointer" for="patient">
                                    Confirmer le patient sélectionné
                                </label>
                            </div> -->
                            <!-- <div @click="SelectPatient(find.user_id, datas), (patientSelect = false)" class="form-check p-0 cursor-pointer my-2" v-if="patientSelect">
                                <i class="fa-regular fa-square"></i>
                                <label class="form-check-label mx-2 cursor-pointer" for="patient">
                                    Confirmer le patient sélectionné 
                                </label>
                            </div> -->
                            <div class="d-flex justify-content-center mt-4" >
                                <button
                                    @click="SelectPatient(find.user_id, datas), (patientSelect = true)"
                                    class="btn btn-secondary py-2 shadow">
                                    Continuer vers le formulaire
                                </button>
                                <!-- <button type="button" class="btn btn-secondary py-2 shadow"></button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </div>
</template>
<script setup lang="ts">
import Modal from '@/views/Admin/Teleconsultation/Modal.vue';
import VueMultiselect from 'vue-multiselect'
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'

    const props = defineProps({
        btn_text: {
            type: String,
            default: 'button'
        },
        routeName: {
            type: String,
            default: ''
        }
    })

    const store = useStore();
    const router = useRouter();
    const modalVisible = ref(false);
    const find = ref([]);
    const searchData: [] | any = ref([])
    const patientSelect = ref(false)
    const isLoading = ref(false);
    const datas = "user,dossier";

    function gotoTeleconsultationForm() {
        modalVisible.value = false;
        // router.push({ name: 'create-bulletin-examen'});
        window.location.href = props.routeName;
    }

    const DataPatient = (find: "") => {
        isLoading.value = true;
        store.dispatch('patient/searchPatient', find);
        searchData.value = store.state.patient.patient;
        isLoading.value = false;
    }

    function SelectPatient(id: 0, associations: "") {
        store.dispatch('patient/fetchPatient', { id: id, associations: associations }).then(() => {
            gotoTeleconsultationForm()
        })
    }

    const confirmPatient = (patient: "") => {
        patientSelect.value = true
    }
</script>