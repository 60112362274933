<template lang="">
    <div class="medicament default">
        <button @click="open = true, horaireUpdate = [], state.medicament = {}, isUpdate = false" type="button" class="btn btn-dark shadow float-end my-2 mx-3">
            <i class="fa fa-plus px-2"></i> Nouveau médicament
        </button>
        <DynamicModal :show="open" @close="open = false" title="Nouveau Médicament">
            <form action="" class="prescription-form">
                <div class="">
                    <div class="d-block my-3 ">
                        <div class="mb-0">
                            <div class="d-flex justify-content-between">
                                <label class="default">Médicaments : <span class="red">*</span></label>
                                <label class="default" v-if="MedicamentData.length">nombre d'élements trouvés : {{ MedicamentData.length }}</label>
                            </div>
                            <div class="row">
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" @keyup="searchMedicament($event)" placeholder="Rechercher un médicament" aria-label="Rechercher un médicament" aria-describedby="basic-addon2">
                                    <div class="input-group-append btn btn-primary" type="button" @click="activateMultiselect">
                                        <span class="input-group-text btn btn-primary" id="basic-addon2">Rechercher <i class="fas fa-search"></i></span>
                                    </div>
                                </div>
                            </div>
                            <!-- @search-change="searchMedicament" -->
                            <VueMultiselect 
                                v-model="state.medicament" 
                                :multiple="false" 
                                ref="multiselect" 
                                :options="MedicamentData ?? []" 
                                :close-on-select="true" 
                                :clear-on-select="false" 
                                :preserve-search="true"
                                :searchable="false"
                                :loading="isLoadingMedicament"
                                :open="openMultiselect"
                                @select="pushData"
                                placeholder="Recherchez/Selectionnez le médicament"
                                label="denomination" track-by="denomination"
                                :preselect-first="true"
                                class="w-md-50"
                            >
                            </VueMultiselect>
                            <em v-if="v$.$error && v$.medicament.$invalid" class="red list-none ">
                                {{ "médicament requis" }} <br>
                            </em>
                        </div>
                        <ul class="medicament-info" v-if="state.medicament?.denomination ">
                            <li>Dénomination : <span>{{ state.medicament.denomination }}</span></li>
                            <li v-for="(item, index) in state.medicament.groupes_generiques" :key="index">
                                Groupes generiques : 
                                <span>{{ item.libelle }}</span>
                            </li>
                            <li>Forme médicamenteuse : <span>{{ isUpdate ? state.medicament.forme_medicamenteuse : state.medicament.forme_pharmaceutique  }}</span></li>
                            <li>Voies d'administrations : <span>{{ isUpdate ? state.medicament.voie_administration : state.medicament.voies_administration }}</span></li>
                        </ul>
                    </div>
                    <div class="d-none">
                        <!-- <div class="mb-3">
                            <label class="default">Voie d'administration : <span class="red">*</span></label>
                            <VueMultiselect v-model="state.admin" :options="VoieAdministration" :clear-on-select="false" :preserve-search="true" placeholder="Selectionnez la voie d'administration" label="libelle" track-by="id" :preselect-first="true">
                                <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                            </VueMultiselect>
                        </div>
                        <div class="mb-3">
                            <label class="default">Forme médicamenteuse : <span class="red">*</span></label>
                            <VueMultiselect v-if="formeMedicamenteuses" v-model="state.forme" :options="formeMedicamenteuse" :clear-on-select="false" :preserve-search="true" placeholder="Selectionnez la forme médicamenteuse" label="libelle" track-by="id" :preselect-first="true">
                                <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                            </VueMultiselect>
                        </div> -->
                        <!-- <div class="mb-3 col-md-6">
                            <label class="default">Catégorie médicamenteuse : <span class="red">*</span></label>
                            <VueMultiselect v-model="state.categorie" :options="CategorieMedicamenteuse" :clear-on-select="false" :preserve-search="true" placeholder="Selectionnez la catégorie médicamenteuse" label="libelle" track-by="id" :preselect-first="false"  @select="formeMedicamenteuses($event)">
                                <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                            </VueMultiselect>
                        </div> -->
                    </div>
                </div>
                <div class="mt-1">
                    <label class="default my-2 fwb h2" for="">{{ state.medicament?.denomination }}</label>
                    <fieldset class="form-group border p-2">
                        <legend class="default float-none w-auto px-2">posologie</legend>
                        <div class="boxDetails w-100">
                            <div class="row pt-2">
                                    <p class="mb-0">
                                        <div class="mb-4">
                                            <label for="" class="default">Horaire de prise : <span class="red">*</span></label>
                                            <b-form-group class="border rounded p-2 w-content mb-0">
                                                <b-form-checkbox-group
                                                    v-model="state.horaire"
                                                    :options="HoraireDePrise"
                                                    class=""
                                                    value-field="id"
                                                    text-field="libelle"
                                                ></b-form-checkbox-group>
                                            </b-form-group>
                                            <em v-if="v$.$error && v$.horaire.$invalid" class="red list-none ">
                                                {{ "horaire de prise requis" }} <br>
                                            </em>
                                        </div>
                                    </p>
                                    <p class="mb-3 d-md-inline-flex">
                                        <span class="default my-2">Prendre : </span>
                                        <span class="col-md-1 mx-2"><input class="form-control quantity" v-model="state.quantite_lors_une_prise" min="0" name="quantity" type="number"> </span>
                                        <span class=" mx-2">
                                            <VueMultiselect v-model="state.presentation" :options="UnitePresentation" :clear-on-select="false" :preserve-search="true" placeholder="Pick some" label="libelle" track-by="id" :preselect-first="true">
                                            </VueMultiselect>
                                        </span>
                                        <span class="d-flex d-block d-md-none">
                                            <span class=""><input class="form-control quantity" min="0" name="quantity" v-model="state.nombre_prises" type="number"> </span>
                                            <span class="my-2 mx-2">fois, tous les</span>
                                        </span>
                                        <span class="mx-2 col-md-1 d-none d-md-block"><input class="form-control quantity" min="0" name="quantity" v-model="state.nombre_prises" type="number"> </span>
                                        <span class="my-2 mx-2 d-none d-md-block">fois, tous les</span>
                                        <span class="col-md-1 mx-2"><input class="form-control quantity" min="0" name="quantity" v-model="state.nombre_de_fois" type="number"> </span>
                                        <span class="">
                                            <VueMultiselect v-model="state.tempsPrise" :options="dateCalender" :clear-on-select="false" :preserve-search="true" placeholder="Choisir un élément" label="name" track-by="name" :preselect-first="true">
                                            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                                            </VueMultiselect>
                                        </span>
                                    </p>
                                    <p class="mb-3 d-md-inline-flex">
                                            <span class="my-2">Pendant : </span>
                                            <span class="col-md-1 mx-2">
                                                <input class="form-control quantity" min="1" name="quantity" v-model="state.duree_traitement" type="number"> 
                                                <em v-if="v$.$error && v$.duree_traitement.$invalid" class="red list-none ">
                                                    {{ "requis" }} <br>
                                                </em>
                                            </span>
                                            <span class=" mx-2">
                                                <VueMultiselect v-model="state.duree_traitemt" :options="dateCalender" :clear-on-select="false" :preserve-search="true" placeholder="Pick some" label="name" track-by="name" :preselect-first="true" class="pb-3 pb-md-0">
                                                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                                                </VueMultiselect>
                                            </span>
                                        <span class="mx-2 d-md-inline-flex my-md-2 mt-4 align-items-center">
                                            <span class="default">Est-il renouvelable{{ renouv ? '(Oui) : ' : ' ? ' }} </span>
                                            <input type="checkbox" class="form-check-input m-0 mx-1" v-model="renouv" />
                                            <span class="d-flex" v-if="renouv">
                                                <span class="mx-2 "><input class="form-control quantity" min="0" name="quantity" v-model="state.nombre_renouvelement" type="number"> </span>
                                                <span class=" my-2">fois</span>
                                            </span>
                                        </span>
                                    </p>

                                    <p class="col-md-6 mb-4">
                                        <label class="default">Intervalle de temps entre deux prises<span class="red">*</span></label>
                                        <!-- <span class="mx-2"><input class="form-control quantity" min="0" name="quantity" v-model="state.intervalle_entre_deux_prises" type="text"> </span> -->
                                        <span class="d-flex">
                                            <span class=""><input class="form-control quantity" min="0" name="quantity" v-model="state.intervalle_entre_deux_prises" type="number"> </span>
                                            <span class=" mx-2">
                                                <VueMultiselect v-model="state.intervalle" :options="dateCalender" :clear-on-select="false" :preserve-search="true" placeholder="Pick some" label="name" track-by="name" :preselect-first="true" class="pb-3 pb-md-0">
                                                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                                                </VueMultiselect>
                                            </span>
                                        </span>
                                    </p>
                                    <div class="row mb-4">
                                        <div class="col-md-4">
                                            <p class="default m-0">Nombres d'unités d'achats : <span class="red">*</span></p>
                                            <input class="form-control quantity" min="0" name="quantity" v-model="state.nombre_unite_achat" type="number">
                                            <em v-if="v$.$error && v$.nombre_unite_achat.$invalid" class="red list-none ">
                                                {{ "nombre d'unité d'achat requis" }} <br>
                                            </em>
                                        </div>
                                        <div class="col-md">
                                            <label class="default">Conditionnement : <span class="red">*</span></label>
                                            <VueMultiselect v-model="state.condition" :options="Conditionnement" :clear-on-select="false" :preserve-search="true" placeholder="Selectionnez les conditions" label="libelle" track-by="id" :preselect-first="true">
                                            </VueMultiselect>
                                            <em v-if="v$.$error && v$.condition.$invalid" class="red list-none ">
                                                {{ "conditionnement requis" }} <br>
                                            </em>
                                        </div>
                                    </div>

                                    <div class="row-md mb-4">
                                        <div class="col-md-6">
                                            <label class="default">Relation avec l'alimentation<span class="red">*</span></label>
                                            <VueMultiselect v-model="state.alimentaire" :multiple="true" :options="RelationAlimentaire" :clear-on-select="false" :preserve-search="true" placeholder="Selectionnez relation avec l'alimentation" label="libelle" track-by="id" :preselect-first="true">
                                                <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                                            </VueMultiselect>
                                        </div>
                                        <em v-if="v$.$error && v$.alimentaire.$invalid" class="red list-none ">
                                            {{ "relations avec l'alimentation requis" }} <br>
                                        </em>
                                    </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="my-3">
                    <button type="button" @click="createMedicament()" class="btn btn-primary float-end">Enregistrer</button>
                </div>
            </form>
        </DynamicModal>

        <!-- listing medicamen ----------------------------------------------->
        <div class="mb-3 col-md-12">
            <div class="icd-instance">
            <div class="d-md-flex justify-content-md-between py-3">
                <div class=" pt-4 px-3">
                <h5 class="typo__label">Liste des Médicaments</h5></div>
            </div>
            <div class="mx-md-4 pb-5 d-md-block d-none">
                <div class="rounded taille align-items-center row mt-2">
                    <div class="col-md-10">
                        <ul class="list-none px-2 py-2 row-lg table-header gap-2 d-flex align-items-center m-0">
                            <li class="col-lg nom fwb">Dénomination</li>
                            <li class="col-lg d-lg-block fwb">Forme médicam..</li>
                            <li class="col-lg d-lg-block fwb">Voies d'admin..</li>
                            <!-- <li class="col-lg d-lg-block text-cnter fwb"><p class="text-center mb-0">Durée</p></li> -->
                        </ul>
                    </div>
                    <div class="col-md-2">
                        <ul class="list-none px-2 py-2 row-lg table-header gap-2 justify-content-between d-flex align-items-center m-0">
                            <li class="col-lg d-none d-lg-block fwb">Action</li>
                        </ul>
                    </div>
                </div>
                    <div 
                        v-if="route.params.uuid"
                        v-for="(medicament, index) in getMedicamentUpdate" :key="index"
                        class="rounded bg-white align-items-center row px-1 mb-1">
                        <div class="col-md-10 cursor-pointer" @click="openShow(item)">
                            <ul 
                                class="list-none d-flex row-lg default fwn px-2 py-3 mb-0 gap-2 justify-content-between align-items-center" id="modalTC"
                            >
                                <!--  card-active <li class="col-lg "><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></li> -->
                                <li class="col-lg d-flex align-items-center gap-1">
                                    {{ useTruncate(medicament.denomination, 24) }}  
                                </li>
                                <li class="col-lg d-flex align-items-center gap-1">
                                    {{ useTruncate(medicament.forme_pharmaceutique, 24) }}
                                </li>
                                <li class="col-lg d-flex align-items-center gap-1">
                                    {{ useTruncate(medicament.voies_administration, 18) }}
                                </li>
                                <!-- <li class="col-lg text-center gap-1">
                                    {{ medicament.dure }}
                                </li> -->
                            </ul>
                        </div>
                        <div class="col-md-2">
                            <ul class="list-none px-2 py-2 table-header gap-2 d-flex align-items-center mb-0">
                                <button type="button" class="w-content btn fwb" @click="openMedicament(medicament, index)"><i class="fa fa-edit"></i></button>
                                <button @click="useMedicament.removeMedicamentUpdate(medicament?.denomination)" type="button" class="btn d-none d-lg-block fwb"><i class="fa fa-trash red"></i></button>
                            </ul>
                        </div>
                    </div>
                    <div 
                        v-if="getMedicament"
                        v-for="(medicament, index) in getMedicament" :key="index"
                        class="rounded bg-white align-items-center row px-1 mb-1">
                        <div @click="openShow(item)" class="col-md-10 cursor-pointer">
                            <ul 
                                class="list-none d-flex row-lg default fwn px-2 py-3 mb-0 gap-2 justify-content-between align-items-center" id="modalTC"
                            >
                                <!--  card-active <li class="col-lg "><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></li> -->
                                <li class="col-lg d-flex align-items-center gap-1">
                                    {{ useTruncate(medicament.denomination, 24) }}
                                </li>
                                <li class="col-lg d-flex align-items-center gap-1">
                                    {{ useTruncate(medicament.forme_medicamenteuse, 24) }}
                                </li>
                                <li class="col-lg d-flex align-items-center gap-1">
                                    {{ useTruncate(medicament.voie_administration, 18) }}
                                </li>
                                <!-- <li class="col-lg text-center gap-1">
                                    {{ medicament.dure }}
                                </li> -->
                            </ul>
                        </div>
                        <div class="col-md-2">
                            <ul class="list-none px-2 py-2 table-header gap-2 d-flex align-items-center mb-0">
                               <!--  <button type="button" class="w-content btn fwb" @click="openCreateMedicament(medicament, index)"><i class="fa fa-edit"></i></button> -->
                                <button @click="useMedicament.removeMedicament(medicament?.denomination), getMedicament = []" type="button" class="btn d-none d-lg-block fwb"><i class="fa fa-trash red"></i></button>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- version mobile -->
                <div class="shadow d-block d-md-none">
                    <div class="row justify-content-center mb-2 pr-2" role="status">
                        <div class="loading"></div>
                    </div>
                    <div class="list-group list-group-flush" v-for="(medicament, index) in getMedicament" :key="index">
                        <div class="d-flex flex-row justify-content-between w-100 p-1 my-1 list-group-item" >
                            <div class="">
                                <span for="" class="my-2 text-h5 fw-semibold">Denomination:</span>
                                <h6 class=" colorGrey text-uppercase pt-1 text-h5"> {{ useTruncate(medicament.denomination, 15) }}  </h6>
                            </div>
                            <div class="" @click="useMedicament.removeMedicament(medicament?.denomination)">
                                <i class="fa fa-trash px-2 text-dark text-h5"></i>
                            </div>
                        </div>      
                    </div>    
                </div>
            </div>
        </div>
    </div> 
</template>
<script setup>
import VueMultiselect from 'vue-multiselect';
import * as ECT from '@whoicd/icd11ect';
import { useVuelidate } from '@vuelidate/core'
import '@whoicd/icd11ect/style.css';
import _ from 'lodash';
import { useStore } from 'vuex';
import { storeToRefs } from 'pinia';
import { required } from '@vuelidate/validators';
import PrescriptionService from "@/_services/PrescriptionService";
import MultiStepsForm from '@/components/form/MultiStepsForm.vue';
import { useRouter, useRoute } from 'vue-router';
import { ref, reactive, inject, onMounted, computed, watch, nextTick } from 'vue';
import { usePrescription } from "@/_storeV2/eprescription";
import { storeMedicament } from "@/_storeV2/medicament";
import { useTruncate } from "@/_composables/useTruncate"
import EtablissementModule from '../../../store/modules/etablissement/index';
import DynamicModal from '@/components/organisms/DynamicModal.vue'
const swal = inject('$swal');
const loading = computed(() => store.getLoading);
const prescStore = usePrescription();
const xstore = useStore();
const useMedicament = storeMedicament();
const oneMedicament = ref({});
const router = useRouter();
const route = useRoute();
// Globale
const props = defineProps({
    state: {},
    v$: {},
    medicaments: {
        required: false,
        default: false
    }
})

const openMultiselect = ref(true);
const multiselect = ref(null);
const Status = ref(false)
const steps = reactive([
    { title: 'Documentation', slotName: 'step1' },
    { title: 'Données', slotName: 'step2' },
    // { title: 'Examen(s)', slotName: 'step3' },
])

const open = ref(false);


const dateCalender = ref([
    { id: 1, name: 'Heures', language: 'Heures' },
    { id: 2, name: 'Jours', language: 'Jours' },
    { id: 3, name: 'Semaines', language: 'Semaines' },
    { id: 4, name: 'Mois', language: 'Mois' },
    { id: 5, name: 'Année', language: 'Année' },
])

const getHoraire = computed(() => {
    console.log(oneMedicament.horaire)
})



const horaireUpdate = ref(null)
const alimentaireUpdate = ref(null)
// const categorieUpdate = ref(null)
const conditionUpdate = ref(null)
const dureUpdate = ref(null)
const presentationUpdate = ref(null)
const nombre_renouvelementUpdate = ref(null)
const  nombre_unite_achatUpdate = ref(null)
const  nombre_de_foisUpdate = ref(null)
const  nombre_prisesUpdate = ref(null)
const  quantite_lors_une_priseUpdate = ref(null)

const state = reactive({
    quantite_lors_une_prise: quantite_lors_une_priseUpdate ,
    duree_traitement : dureUpdate,
    nombre_renouvelement : route.params.uuid ? nombre_renouvelementUpdate : 0,
    nombre_unite_achat : route.params.uuid ? nombre_unite_achatUpdate : 1,
    nombre_de_fois : nombre_de_foisUpdate,
    intervalle_entre_deux_prises : 1,
    intervalle : '',
    intervalle_entre_deux_prise_concat: computed(() => state.intervalle_entre_deux_prises + ' ' + state.intervalle.name),
    tempsPrise : '',
    duree_traitemt : '',
    nombre_prises: 1,
    doc: true,
    elements: false,
    maladie: [],
    forme: [],
    condition: conditionUpdate ,
    admin: [],
    alimentaire: alimentaireUpdate ,
    // categorie: categorieUpdate ,
    horaire: horaireUpdate ,
    presentation: presentationUpdate  ,
    evenement: '',
    medicament: oneMedicament,
});

const renouv = ref(false)

const rules = reactive({
    condition: { required },
    medicament: { required },
    alimentaire: { required },
    quantite_lors_une_prise: { required },
    duree_traitement : { required },
    nombre_prise : { required },
    presentation: { required },
    nombre_de_fois : { required },
    intervalle_entre_deux_prises : { required },
    tempsPrise : { required },
    nombre_unite_achat: { required },
    duree_traitemt : { required },
    intervalle : { required },
    horaire: { required },
});

const v$ = useVuelidate(rules, state);
const isUpdate = ref(false)
const createMedicament = () => {
    v$.value.$touch()
    if(!v$.value.medicament.$error && !v$.value.condition.$error 
    && !v$.value.horaire.$error && !v$.value.alimentaire.$error && !v$.value.nombre_unite_achat.$error
    && !v$.value.duree_traitemt.$error){
        if(isUpdate.value == false){
            useMedicament.createMedicament(state).then(() => {
                open.value = false
                console.log('isUpdate.value ', isUpdate.value)
                state.medicament = {}
                state.alimentaire = []
                state.condition = {}
                state.quantite_lors_une_prise = 1
                state.nombre_de_fois = 1
                state.duree_traitement = 1
                state.intervalle_entre_deux_prises = 1
                state.nombre_prises = 1
            })
        }else{
            console.log("state ", state)
            useMedicament.updatedMedicaments(state).then(() => {
                console.log('okkkkkkkkkkkkk')
                open.value = false
                isUpdate.value == false
                state.medicament = {}
                state.alimentaire = []
                state.condition = {}
                state.quantite_lors_une_prise = 1
                state.nombre_de_fois = 1
                state.duree_traitement = 1
                state.intervalle_entre_deux_prises = 1
                state.nombre_prises = 1
            })
        }
    }else{
        console.log('Notok')
    }
}
const activateMultiselect = () => {
      nextTick(() => {
        multiselect.value && multiselect.value.activate();
      });
    };
const openMedicament = (medicament) => {
    console.log("medicament", medicament)
    open.value = true
    oneMedicament.value = medicament
    horaireUpdate.value = medicament?.horaire 
    alimentaireUpdate.value = medicament.relation_alimentaires
    // categorieUpdate.value = medicament.categorie_medicamenteuse
    conditionUpdate.value = medicament.conditionnement
    dureUpdate.value = medicament.dure
    presentationUpdate.value = medicament.presentation
    nombre_renouvelementUpdate.value = medicament.nombre_renouvelement
    nombre_unite_achatUpdate.value = medicament.nombre_unite_achat
    nombre_de_foisUpdate.value = medicament.nombre_de_fois
    quantite_lors_une_priseUpdate.value = medicament.quantite_lors_une_prise
}

const openCreateMedicament = (medicament) => {
    console.log("medicament update", medicament)
    open.value = true
    isUpdate.value = true
    console.log("isUpdate", isUpdate.value)
    oneMedicament.value = medicament
    horaireUpdate.value = medicament?.horaire 
    alimentaireUpdate.value = medicament.relation_alimentaires
    // categorieUpdate.value = medicament.categorie_medicamenteuse
    conditionUpdate.value = medicament.conditionnement
    dureUpdate.value = medicament.dure
    presentationUpdate.value = medicament.presentation

    nombre_renouvelementUpdate.value = medicament.nombre_renouvelement
    nombre_unite_achatUpdate.value = medicament.nombre_unite_achat
    nombre_de_foisUpdate.value = medicament.nombre_de_fois,
    quantite_lors_une_priseUpdate.value = medicament.quantite_lors_une_prise

    /**
     * Mise à jour
     */
    state.condition = medicament.conditionnement
    state.nombre_unite_achat = medicament.nombre_unite_achat
    state.alimentaire = medicament.relation_alimentaires
    state.quantite_lors_une_prise = medicament.quantite_lors_une_prise
    state.intervalle_entre_deux_prises = medicament.intervalle_entre_deux_prises
    state.intervalle = medicament.intervalle
    state.duree_traitemt = medicament.duree_traitemt
    state.duree_traitement = medicament.dure
    state.tempsPrise = medicament.tempsPrise
    state.nombre_prises = medicament.nombre_prises
    state.horaire = medicament.horaire
    state.presentation = medicament.presentation

    /* medicament

     */


}

if(route.params.uuid){
    useMedicament.medicamentUpdate = props.medicaments ?? []
    localStorage.setItem('medicament_c', JSON.stringify(useMedicament.medicamentUpdate))
}

// const MedicamenLocal = JSON.parse(localStorage.getItem('medicament_c'))
const getMedicamentUpdate = computed(() => {
    if(route.params.uuid){
        return useMedicament.medicamentUpdate?.map((item) => {
            return {
                denomination: item.denomination,
                CIS: item.code,
                forme_pharmaceutique: item.forme_pharmaceutique,
                voies_administration: item.voie_administration_id,
                dure: item.pivot.duree_traitement,
                conditionnement: item.conditionnement,
                nombre_de_fois: item.pivot.nombre_de_fois,
                nombre_renouvelement: item.pivot.nombre_renouvelement,
                nombre_unite_achat: item.pivot.nombre_unite_achat,
                quantite_lors_une_prise: item.pivot.quantite_lors_une_prise,
                presentation: item.unite_presentation,
                horaire: item.horaire_de_prises.map((h) => h.id),
                categorie_medicamenteuse: item.categorie_medicamenteuse,
                relation_alimentaires: item.relation_alimentaires
            }
        })
    }
})

const getMedicament = computed(() => {
    return useMedicament.medicaments?.map((item) => {
            console.log("item ", item)
        return {
            denomination : item.medicament?.denomination,
            forme_medicamenteuse: item.medicament?.forme_pharmaceutique,
            voie_administration: item.medicament?.voies_administration,
            dure: item?.duree_traitement,
            duree_traitemt: item?.duree_traitemt,
            intervalle_entre_deux_prises: item?.intervalle_entre_deux_prises,
            intervalle: item?.intervalle,
            tempsPrise: item?.tempsPrise,
            conditionnement: item.condition,
            nombre_de_fois: item.nombre_de_fois,
            nombre_prises: item.nombre_prises,
            nombre_renouvelement: item.nombre_renouvelement,
            nombre_unite_achat: item.nombre_unite_achat,
            quantite_lors_une_prise: item.quantite_lors_une_prise,
            presentation: item.presentation,
            horaire: item.horaire,
            categorie_medicamenteuse: item.categorie_medicamenteuse,
            relation_alimentaires: item.alimentaire
        }
    })

})

const submitForm = () => {
    v$.value.$validate()
    if(!v$.value.$error 
        && (v$.value.quantite_lors_une_prise.$model != null)
        && (v$.value.duree_traitement.$model != null)
        && (v$.value.nombre_prise.$model != null)
        && (v$.value.nombre_renouvelement.$model != null)
        && (v$.value.nombre_de_fois.$model != null)
        && (v$.value.tempsPrise.$model != null)
        && (v$.value.duree_traitemt.$model != null)
        && (v$.value.nombre_unite_achat.$model != null)
        && (v$.value.presentation.$model != null)
        && (v$.value.intervalle.$model != null)
        ){
            if(route.params.uuid){
                Status.value = store.loading;
            }else{
                Status.value = store.loading;
            }
    }else{
        console.log('Notok')
        setTimeout(() => {
            // showAlert.value = false;
        }, 10000);
        Status.value = false
    }
}

//Get form elements
const formeMedicamenteuse = ref([])
const formeMedicamenteuses = async (event) => {
    await PrescriptionService.getFormeMedicamenteuses(event.id)
        .then(response => {
            formeMedicamenteuse.value = response.data.data.data;
            return formeMedicamenteuse.value
        })
        .catch(error => {
            console.log(error);
        });
}
const Conditionnement = ref([])
const Conditionnements = async () => {
    await PrescriptionService.getConditionnements()
        .then(response => {
            Conditionnement.value = response.data.data
        })
        .catch(error => {
            console.log(error);
        });
}
const CategorieMedicamenteuse = ref([])
const CategorieMedicamenteuses = async () => {
    await PrescriptionService.getCategorieMedicamenteuses()
        .then(response => {
            CategorieMedicamenteuse.value = response.data.data
        })
        .catch(error => {
            console.log(error);
        });
}
const VoieAdministration = ref([])
const VoieAdministrations = async () => {
    await PrescriptionService.getVoieAdministration()
        .then(response => {
            VoieAdministration.value = response.data.data
        })
        .catch(error => {
            console.log(error);
        });
}
const UnitePresentation = ref([])
const UnitePresentations = async () => {
    await PrescriptionService.getUnitePresentations()
        .then(response => {
            UnitePresentation.value = response.data.data
        })
        .catch(error => {
            console.log(error);
        });
}

const HoraireDePrise = ref([])
const HoraireDePrises  = async () => {
    await PrescriptionService.getHoraireDePrises()
        .then(response => {
            HoraireDePrise.value = response.data.data
        })
        .catch(error => {
            console.log(error);
        });
}
const RelationAlimentaire = ref([])
const RelationAlimentaires = async () => {
    await PrescriptionService.getRelationAlimentaires()
        .then(response => {
            RelationAlimentaire.value = response.data.data
        })
        .catch(error => {
            console.log(error);
        });
}
const isLoadingMedicament = ref(false)
const MedicamentData = ref([]);
const searchMedicament = (query) => {
    query = query.target.value;
    
    if(query?.length > 0){
        query = query.split(' ');
        isLoadingMedicament.value = true
        useMedicament.filterMedicamentByDenomination(query).then(() => {
            MedicamentData.value = useMedicament.data
            isLoadingMedicament.value = false
        })
    }
}

const formMedicamenteuseData = ref([])
//push forme_pharmaceutique select to formMedicamenteuseData
const pushData = computed(() => {
    // useMedicament.isUpdate = false
    if (state.medicament) {
        formMedicamenteuseData.value.push(state.medicament.forme_pharmaceutique)
    }
})


onMounted(() => {
    // mySettings.value;
    // formeMedicamenteuses();
    Conditionnements();
    CategorieMedicamenteuses();
    // VoieAdministrations()
    UnitePresentations();
    HoraireDePrises();
    RelationAlimentaires();

    activateMultiselect();

    if(localStorage.getItem('medicament')){
        console.log(props.medicaments)
    }else{
        console.log("getMedicament", props.medicaments)
    }
})

</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>