import { defineStore } from "pinia";
import PrescriptionService from "@/_services/PrescriptionService";
import { useToast } from 'vue-toast-notification';
import router from "@/router";


export interface Prescription {
    id: number;
    libelle: string;
    data: any;
}

export interface FormeMedicamenteuses {
    id: number;
    libelle: string;
}

export interface Conditionnements {
    id: number;
    libelle: string;
}

export interface CategorieMedicamenteuses {
    id: number;
    libelle: string;
}

export interface VoieAdministrations {
    id: number;
    libelle: string;
}

export interface UnitePresentations {
    id: number;
    libelle: string;
}

export interface HoraireDePrises {
    id: number;
    libelle: string;
}

export interface RelationAlimentaires {
    id: number;
    libelle: string;
}

export const usePrescription = defineStore({
    id: 'eprescription',
    state: () => ({
        $toast: useToast(),
        // State
        //
        prescriptions: {
            data: []
        } as Prescription,
        prescription: {} as Prescription[] | null,
        formeMedicamenteuses: {} as FormeMedicamenteuses[] | null,
        conditionnements: {} as Conditionnements[] | null,
        categorieMedicamenteuses: {} as CategorieMedicamenteuses[] | null,
        voieAdministrations: {} as VoieAdministrations[] | null,
        unitePresentations: {} as UnitePresentations[] | null,
        horaireDePrises: {} as HoraireDePrises[] | null,
        relationAlimentaires: {} as RelationAlimentaires[] | null,
        loading: false
    }),
    getters: {
        getPrescription(): Object {
            return this.prescriptions;
        },
        getRelationAlimentaires(): Object | any {
            return this.relationAlimentaires;
        },
        getFormeMedicamenteuses(): Object | any {
            return this.formeMedicamenteuses;
        },
        getConditionnements(): Object | any {
            return this.conditionnements;
        },
        getCategorieMedicamenteuses(): Object | any {
            return this.categorieMedicamenteuses;
        },
        getVoieAdministrations(): Object | any {
            return this.voieAdministrations;
        },
        getUnitePresentations(): Object | any {
            return this.unitePresentations;
        },
        getHoraireDePrises(): Object | any {
            return this.horaireDePrises;
        },
        getOnePrescription(): Object | any {
            return this.prescription;
        },
    },
    actions: {
        // Actions

        async fetchRelationAlimentaires() {
            this.loading = true
            await PrescriptionService.getRelationAlimentaires()
                .then((res: any) => {
                    this.loading = false
                    this.relationAlimentaires = res.data
                })
                .catch((err: any) => console.log(err))
        },

        async fetchPrescriptions(paginate = { page: 1, page_size: 5 , search: ""}) {
            this.loading = true
            await PrescriptionService.getPrescriptions(paginate.page, paginate.page_size, paginate.search)
                .then((res: any) => {
                    this.loading = false
                    this.prescriptions = res.data.data
                })
                .catch((err: any) => console.log(err))
        },
        async fetchFormeMedicamenteuses(id: number) {
            this.loading = true
            await PrescriptionService.getFormeMedicamenteuses(id)
                .then((res: any) => {
                    this.loading = false
                    this.formeMedicamenteuses = res.data
                })
                .catch((err: any) => console.log(err))
        },

        async fetchConditionnements(paginate = { page: 1, page_size: 5 }) {
            this.loading = true
            await PrescriptionService.getConditionnements(paginate.page, paginate.page_size)
                .then((res: any) => {
                    this.loading = false
                    this.conditionnements = res.data.data
                })
                .catch((err: any) => console.log(err))
        },

        async fetchCategorieMedicamenteuses(paginate = { page: 1, page_size: 5 }) {
            this.loading = true
            await PrescriptionService.getCategorieMedicamenteuses(paginate.page, paginate.page_size)
                .then((res: any) => {
                    this.loading = false
                    this.categorieMedicamenteuses = res.data.data
                })
                .catch((err: any) => console.log(err))
        },

        async fetchVoieAdministration() {
            this.loading = true
            await PrescriptionService.getVoieAdministration()
                .then((res: any) => {
                    this.loading = false
                    this.voieAdministrations = res.data
                })
                .catch((err: any) => console.log(err))
        },

        async fetchUnitePresentations() {
            this.loading = true
            await PrescriptionService.getUnitePresentations()
                .then((res: any) => {
                    this.loading = false
                    this.unitePresentations = res.data
                })
                .catch((err: any) => console.log(err))
        },

        async fetchHoraireDePrises() {
            this.loading = true
            await PrescriptionService.getHoraireDePrises()
                .then((res: any) => {
                    this.loading = false
                    this.horaireDePrises = res.data
                })
                .catch((err: any) => console.log(err))
        },

        async getPrescriptionId(uuid: any) {
            this.loading = true
            await PrescriptionService.getPrescription(uuid).then((res: any) => {
                this.loading = false
                this.prescription = res.data.data
            })
                .catch((err: any) => console.log(err));
            // this.bulletins = this.bulletins.filter(bulletin => bulletin.id == id);
        },

        async deletePrescription(id: any) {
            this.loading = true
            await PrescriptionService.deletePrescription(id).then((res: any) => {
                this.loading = false
                this.$toast.warning('Prescription supprimée avec succès', { position: 'top-right' });
            })
                .catch((err: any) => {
                    this.$toast.error('Une erreur est survenue, Veuillez réesseyer', { position: 'top-right' })
                    console.log(err)
                });
        },

        async printPrescription(id: any) {
            await PrescriptionService.printPrescription(id).then((res: any) => {
                console.log(id);
                // this.fetchPrescriptions({page: 1, page_size: 5})
            })
                .catch((err: any) => console.log(err));
        },

        async createPrescription(prescription: any) {
            let MedicamentStorage = JSON.parse(localStorage.getItem('medicament') || '[]')
            let MedicamentId = MedicamentStorage.map((item: any) => item)
            const prescriptions = {
                creator: prescription.creator,
                ligne_temps_id: prescription.evenement_medical.id.id,
                niveau_urgence_id: prescription.niveau_urgence.id.id,
                option_financement_id: prescription.option_financement.id,
                etablissement_id: prescription.etablissement.id.id,
                motif: prescription.motif.id,
                medecin_id: prescription.medecin_id,
                patient_id: prescription.patient_id,
                date_heure: prescription.date_heure,
                raison_prescription_id: prescription.raison_prescription.id.id,
                medicaments: MedicamentId,
            }
            this.loading = true
            await PrescriptionService.createPrescription(prescriptions)
                .then((res: any) => {
                    this.loading = false
                    this.prescriptions = res.data.data
                    this.$toast.success('Prescription créé avec succès', { position: 'top-right' })
                    router.push({ name: 'eprescription-affichage', params: { uuid: res.data.data?.uuid } })
                    localStorage.removeItem('medicament');
                })
                .catch((err: any) => {
                    this.$toast.error('Une erreur est survenue, Veuillez réesseyer', { position: 'top-right' })
                    console.log(err)
                })
        },

        async updatePrescription(uuid: string, prescription: any) {
            let MedicamentStorage = JSON.parse(localStorage.getItem('medicament') || '[]')
            let MedicamentCStorage = JSON.parse(localStorage.getItem('medicament_c') || '[]')
            let MedicamentId = MedicamentStorage.map((item: any) => item)
            const prescriptions = {
                creator: prescription.creator,
                ligne_temps_id: prescription.evenement_medical.id.id,
                niveau_urgence_id: prescription.niveau_urgence.id.id,
                option_financement_id: prescription.option_financement.id,
                etablissement_id: prescription.etablissement.id.id,
                motif: prescription.motif.id,
                medecin_id: prescription.medecin_id,
                patient_id: prescription.patient_id,
                date_heure: prescription.date_heure,
                raison_prescription_id: prescription.raison_prescription.id.id,
                medicaments: MedicamentId,
                medicamentsUpdate: MedicamentCStorage
            }
            this.loading = true
            await PrescriptionService.updatePrescription(uuid, prescriptions)
                .then((res: any) => {
                    this.loading = false
                    this.prescriptions = res.data.data
                    this.$toast.success('Prescription modifié avec succès', { position: 'top-right' })
                    router.push({ name: 'eprescription-affichage', params: { uuid: res.data.data?.uuid } })
                    localStorage.removeItem('medicament');
                })
                .catch((err: any) => {
                    this.$toast.error('Une erreur est survenue, Veuillez réesseyer', { position: 'top-right' })
                    console.log(err)
                })
        }
    }
});