import Axios from "@/api"
// import AxiosSignature from "@/api"
class AccountService {
    //fonction de connection
     login = (credentials : {} | string) => {
        return Axios.post('oauth/token', credentials)
    }
    
    //fonction de deconnection, ici on supprime tout simplement le token du localstorage
    logout = () => {
        localStorage.removeItem('patient-slug')
        localStorage.removeItem('vuex')
        localStorage.clear()
    }

    // récupération de l'utilisateur connecté
    setUser = () => {
        return Axios.get('user/medecin')
    }

    //stockage du token
    saveToken = (token: string) => {
        localStorage.setItem('ms-token', token)
    }
    //récupération signature
    saveSignature = (signature: any, user_id: Number) => {
        return Axios.post('signature/user', {signature: signature, id: user_id})
    } 

    //stockage du token
    saveCurrentPatientSlug = (slug: string) => {
        localStorage.setItem('patient-slug', slug)
    }

    //on recupere le token
    getToken = () => {
        return localStorage.getItem('ms-token')
    }
    //on recupere le patient courant
    getPatientSlug = () => {
        return localStorage.getItem('patient-slug')
    }

    //methode qui determine si on est connectee ou pas
    isLogged = () => {
        const token = localStorage.getItem('ms-token')
        return !!token
    }
}


export default new AccountService();