import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { Pagination } from "@/_types"

import { Anamnese, AnamneseCreate } from "@/_types";
import anamneseService from "@/_services/anamneseService";

import { useToast } from 'vue-toast-notification';

@Module({ namespaced: true })
export default class AnamneseModule extends VuexModule {
    anamnese: Pagination | null = null;

    loadingStatus: boolean = false;

    $toast = useToast();
    // getters anamnese
    get gAnamnese() {
        return this.anamnese;
    }

    //getters loading
    get gloadingStatus() {
        return this.loadingStatus
    }

    @Mutation
    mloadingStatus(newStatus: boolean) {
        this.loadingStatus = newStatus
    }

    // mutations
    @Mutation
    mAnamnese(anamnese: Pagination) {
        this.anamnese = anamnese;
    }

    // actions
    @Action
    async fetchAnamnese(paginate = { page: 1 }) {
        await anamneseService.getAnamneses(paginate.page)
            .then((res: Pagination) => {
                this.context.commit("mAnamnese", res.data.data);
            })
            .catch((error: any) => {
                console.log("error anamnese", error);
            });
    }

    @Action
    async searchAnamnese(element = { search: "", page: 1 }) {
        await anamneseService.searchEAnamnese(element.page, element.search)
            .then((res: Pagination) => {
                this.context.commit("mAnamnese", res.data.data);
            })
            .catch((error: any) => {
                console.log("error anamnese", error);
            });
    }

    @Action
    async createdAnamnese(anamnese: AnamneseCreate | any) {
        this.context.commit('mloadingStatus', true)
        const anamneses = {
            fr_description: anamnese.description,
            anamnese_id: anamnese?.anamnese_id.id,
            teleconsultation_id: anamnese.teleconsultation_id,
        }

        await anamneseService.createdAnamnese(anamneses)
            .then((res: AnamneseCreate | any) => {
                this.context.commit('mloadingStatus', false)
                let teleconsultation = { ...this.context.rootState.teleconsultations.teleconsultation, anamneses: res.data.data.anamneses };

                this.context.commit("teleconsultations/getTeleconsultationHandler", teleconsultation, { root: true });
                this.$toast.success('Anamnese créée avec succès', { position: "top-right" })

                this.context.commit("mAnamnese");
                return true //Promise.resolve(res)
            })
            .catch((error: any) => {
                console.log(error)
                this.context.commit('mloadingStatus', false)
            })
    }

    @Action
    async deleteAnamnese(id: 0) {
        this.context.commit('mloadingStatus', true)
        await anamneseService.deleteAnamnese(id).then((res: any) => {
            this.context.commit('mloadingStatus', false)
            let anamnese = this.context.rootState.teleconsultations.teleconsultation.anamneses?.filter((item: any) => item.id != res.data.data.id);
            let teleconsultation = { ...this.context.rootState.teleconsultations.teleconsultation, anamneses: anamnese };
            this.context.commit("teleconsultations/getTeleconsultationHandler", teleconsultation, { root: true });
            this.$toast.success('Anamnèse supprimée avec succès', { position: "top-right" })
        }).catch((error: any) => console.log(error))
    }
}

