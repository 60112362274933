import { defineStore } from 'pinia'
import { reactive } from 'vue'
import MedicamentService from '@/_services/MedicamentService'
import PrescriptionService from '@/_services/PrescriptionService';

export const storeMedicament = defineStore({
    id: 'médicament',
    state: () => ({
        // state
        data: reactive([]),
        medicaments: reactive([]) as [] | any,
        medicamentUpdate: reactive([]) as [] | any ,
        loading: false,
        HoraireDePrise: [],
    }),

    //getters
    getters: {
        getData() : {} {
            return this.data;
        },
        getDataUpdate()  {
            this.medicamentUpdate = JSON.parse(localStorage.getItem('medicament_c') || '[]');
        }
    },
    actions: {
        // actions   
        // recuperations des medicaments
        recuperationMedicaments(){
            this. medicaments = JSON.parse(localStorage.getItem('medicament') || '[]');
        },
        //filter medicaments by denomination
        async filterMedicamentByDenomination(denomination: []) {
            this.loading = true;
            this.data = await MedicamentService.filterMedicamentByDenomination(denomination);
            console.log(this.data.length);
            this.loading = false;
        },
        async HoraireDePrises(){
            await PrescriptionService.getHoraireDePrises()
                .then(response => {
                    this.HoraireDePrise = response.data.data
                    return this.HoraireDePrise
                })
                .catch(error => {
                    console.log(error);
                });
        },

        //create medicament and save localstorage
        async createMedicament(medicament: [] | any) {
            let dataM : [] | any = JSON.parse(localStorage.getItem('medicament') || '[]');
            if((dataM?.length) || dataM != null){
                if(dataM.find((item: any) => item.medicament.CIS == medicament.medicament.CIS)){
                    dataM = dataM.map((item: any) => item.medicament.CIS == medicament.medicament.CIS ? medicament : item);
                }else{
                    dataM = [...dataM, medicament];
                }
                this.medicaments = [...new Set([...dataM, medicament])];

            }else{
                this.medicaments = [...new Set([...dataM, medicament])];
            }
            localStorage.setItem('medicament', JSON.stringify(this.medicaments));
            this.medicaments = JSON.parse(localStorage.getItem('medicament') || '[]');
        },

        //medicamentUpdate and save localstorage
        async updatedMedicaments(updatedMedicament: any) {
            console.log("updatedMedicament ", updatedMedicament)
            /* let dataM = JSON.parse(localStorage.getItem('medicament') || '[]');
            const updatedDataM = dataM.map((item: any) => item.medicament.denomination == updatedMedicament.medicament.denomination ? updatedMedicament : item)
            this.medicaments = [...new Set(updatedDataM)];
            console.log(" this.medicaments ",  this.medicaments)
            localStorage.setItem('medicament', JSON.stringify(this.medicaments)); */
        },

        //delete medicament,
        async removeMedicament(denomination: string) {
            this.medicaments =  this.medicaments.filter((item: any) => item.medicament.denomination != denomination);
            localStorage.setItem('medicament', JSON.stringify(this.medicaments));
        },

        //delete medicamentUpdate
        async removeMedicamentUpdate(denomination: string) {
            this.medicamentUpdate =  this.medicamentUpdate.filter((item: any) => item.denomination != denomination);
            localStorage.setItem('medicament_c', JSON.stringify(this.medicamentUpdate));
        },

        //get one médicament in store by index
        // async getMedicament(index: number) {
        //     const dataM : [] | any = JSON.parse(localStorage.getItem('medicament') || '[]');
        //     this.medicment = dataM[index];
        //     return this.medicment;
        // },
    },

    //persist data
    persist: {
        enabled: true,
    },
});