<template lang="">
    <div class="default">
        <label class="typo__label p-2">Diagnostic (norme icd)</label><span class="text-danger font-weight-bold">*</span>:
        <div class="icd-instance">
            <div class="icd">
                <!-- <div class="icd-search"> -->
                <b-form-input type="text" placeholder="Rechercher la maladie" class="ctw-input" id="ctw-input" autocomplete="off" data-ctw-ino="1">
                </b-form-input>
            </div>
                Votre sélection est: <span id="icd-paste-selected-1"></span>
            <div class="ctw-window" data-ctw-ino="1"></div>
        </div>
            
        <div class="text-right appointment-item" >
            <div id="icd-selected-1">
                <table class="table table-responsive" >
                        <thead>
                            <tr>
                            <th scope="col">ICD code</th>
                            <th scope="col">name</th>
                            <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item,key) in maladies" :key="key" class="mt-2">
                            <td>{{item.code}}</td>
                            <td> <p  style="">{{item.bestMatchText}}</p></td>
                            <td>
                                <b-button pill variant="danger" @click="remove(item.code)" size="sm">x</b-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        
    </div>
</template>
<script>
import * as ECT from '@whoicd/icd11ect';
import '@whoicd/icd11ect/style.css';
import Editor from '@tinymce/tinymce-vue';

export default {
    components: {
        Editor,
    },
    props:{
        maladies: {
            type: Array,
            default: () => []
        }
    },

    methods: {
    remove(e) {
        // this.maladies = this.maladies.filter(item => item.code !== e);
        this.maladies.splice(this.maladies.indexOf(e), 1);
        // console.log(this.checkedDiagnotics)
    }
  },
    created(){
        const mySettings = {
            apiServerUrl: "https://icd11restapi-developer-test.azurewebsites.net",
            icdMinorVersion: "2023-01",
            language: "fr",
            height: "30vh",
        };

    // example of an Embedded Coding Tool using the callback selectedEntityFunction 
    // for copying the code selected in an <input> element and clear the search results
    const myCallbacks = {
        selectedEntityFunction: selectedEntity => {
            if(this.maladies.some(item => item.code === selectedEntity.code)){
                //alert("You ");
            } else{
                this.maladies.push(selectedEntity);
            }
            // console.log(this.maladies)
            document.getElementById("icd-paste-selected-" + selectedEntity.iNo).innerHTML =selectedEntity.code + " - " + selectedEntity.bestMatchText;
            document.getElementById("icd-selected-" + selectedEntity.iNo).style.display = "inline";
        }
    };

    // configure the ECT Handler with mySettings and myCallbacks
        ECT.Handler.configure(mySettings, myCallbacks);
        // overwrite configuration only for the Embedded Coding Tool 1
        ECT.Handler.overwriteConfiguration("1", {
                wordsAvailable: false
        });
    }
}


</script>
