<template lang="">
    <div>
        <button type="button" class="btn btn-primary p-2 fw-bold teleconsultation-btn" @click="launchDemoModal">
            <i class="fa-solid fa-plus"></i> Nouvelle téléconsultation
        </button>
        <!-- Modal to display the call option -->
        <Modal v-model="dialogVisible" title="Sélectionner un patient" bgcolor="#172b4d" colorTitle="white" notification="true" width="lg">
                <div class="w-100">
                    <div class="mb-3 ">
                        <label class="typo__label default">Rechercher/Ajouter un Patient</label>
                        <div class="w-100 d-block d-md-flex align-items-center" v-if="datas">
                            <VueMultiselect v-model="find" :options="searchData" :preserve-search="true" placeholder="Rechercher un Patient" label="name" :preselect-first="false" :loading="isLoading" @search-change="DataPatient" @select="confirmPatient(find)">
                            </VueMultiselect>
                        </div>
                        <div class="" v-if="find.user_id">
                            <p class="pb-2 mt-4 default">Informations sur le patient selectionné: </p>
                            <div class="container default">
                                <div class="row">
                                    <div class="col">
                                        <p><i class="fa-solid fa-user text-secondary mx-1"></i>Nom: <strong>{{ find.nom }}</strong></p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p><i class="fa-solid fa-user text-secondary mx-1"></i>Prenom: <strong>{{ find.prenom }}</strong></p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p><i class="fa-solid fa-address-book text-secondary mx-1"></i>Adresse: <strong>{{ find.user_id }}</strong></p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p><i class="fa-solid fa-address-book text-secondary mx-1"></i>Téléphone: <a href="#" @click="OpenPhoneLink(find.telephone)"><strong>{{ find.telephone }}</strong></a></p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p><i class="fa-solid fa-calendar-days text-secondary mx-1"></i>Date de Naissance:
                                        <strong>{{ find.date_de_naissance }}</strong></p>
                                    </div>
                                </div>
                                <div class="row border-bottom-dashed">
                                    <div class="col">
                                        <p><i class="fa-solid fa-person text-secondary mx-1"></i>Sexe: <strong>{{ find.sexe }}</strong></p>
                                    </div>
                                    <div class="col">
                                        <p><i class="fa-solid fa-calendar-days text-secondary mx-1"></i> Age: <strong>{{ find.age }}</strong></p>
                                    </div>
                                </div>
                                <div @click="(patientSelect = true), (viewCall = false)" class="form-check p-0 cursor-pointer my-2" v-if="!patientSelect">
                                    <i :class="'primary'" class="fa-regular fa-square-check"></i>
                                    <label class="form-check-label mx-2 cursor-pointer" for="patient">
                                        Confirmer le patient sélectionné
                                    </label>
                                </div>
                                <div @click="SelectPatient(find.user_id, datas), (patientSelect = false), (viewCall = true)" class="form-check p-0 cursor-pointer my-2" v-if="patientSelect">
                                    <i class="fa-regular fa-square"></i>
                                    <label class="form-check-label mx-2 cursor-pointer" for="patient">
                                        Confirmer le patient sélectionné 
                                    </label>
                                </div>
                               <!--  <div v-if="loadingStatus && !patientSelect">
                                    <div class="d-flex justify-content-center">
                                        <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin"/>
                                    </div>
                                </div>
                                <div v-else>
                                    <div v-if="isSupported && !patientSelect" class="d-flex justify-content-center mt-4">
                                        <a href="#" v-if="urlModerator" @click="CopyLink(urlPatient)" class="btn btn-primary py-2 shadow text-center"><i class="fa fa-share-alt"></i> Cliquer ici pour copier le lien patient</a>
                                    </div>
                                    <p v-if="!isSupported">
                                        Your browser does not support Clipboard API
                                    </p>
                                    <div class="d-flex justify-content-center mt-4" v-if="!patientSelect && urlModerator">
                                        <a @click="gotoTeleconsultationForm" href="#" class="btn btn-secondary py-2 shadow">Continuer vers le formulaire</a>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
               <!--  <template v-if="viewCall && !loadingStatus" #footer><icon-call :telephone="find.telephone" @gotoTeleconsultationForm="gotoTeleconsultationForm" :urlModerator="urlModerator" v-if="urlModerator" /></template> -->
        </Modal>
    </div>
</template>
<script setup lang="ts">
import Modal from './Modal.vue';
import { computed, ref } from 'vue';
import VueMultiselect from 'vue-multiselect'
import { useClipboard, usePermission } from '@vueuse/core'
import IconCall from '@/components/icons/IconCall.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toast-notification';

//Methods for Modals
const dialogVisible = ref(false)
const moreInfo = ref(false)

const store = useStore();
const input = ref('');
const $toast = useToast();
const display = computed(() => store.state.composable.display);

const { text, isSupported, copy } = useClipboard()
const permissionRead = usePermission('clipboard-read')
const permissionWrite = usePermission('clipboard-write')

const viewCall = ref(false)

//Call option modal
function launchDemoModal() {
    dialogVisible.value = true
}

defineProps({
    id: Number
})

const router = useRouter();
const isLoading = ref(false);
const patientSelect = ref(false)

const find = ref([])
const searchData: [] | any = ref([])
const datas = "user,dossier";


function gotoTeleconsultationForm() {
    dialogVisible.value = false;
    //router.push({ name: 'AddTeleconsultation'});
    window.location.href = "/teleconsultations/create";
    viewCall.value = false;
}

const DataPatient = (find: "") => {
    isLoading.value = true;
    store.dispatch('patient/searchPatient', find);
    searchData.value = store.state.patient.patient;
    isLoading.value = false;
}

const OpenPhoneLink = (phone: string) => {
    window.open(`tel:${phone}`);
    gotoTeleconsultationForm();
}
const CopyLink = (urlPatient: string) => {
    copy(urlPatient)
    $toast.success('Le lien de la vidéo conférence a été copier avec succès', { position: "bottom-right" })
}


function SelectPatient(id: 0, associations: "") {

    store.dispatch('patient/fetchPatient', { id: id, associations: associations }).then(() => {
        gotoTeleconsultationForm();
    });

}

const confirmPatient = (patient: "") => {
    patientSelect.value = true
}


</script>