import Axios from '../api';
import { Pagination } from '@/_types/pagination';

class MotifTeleconsultation
{
    path: string;

    constructor(){
        this.path = '/motifs';
    }

    async getMotifTeleconsultations(page = 1): Promise<Pagination>{
        return await Axios.get(`${this.path}?page=${page}`);
    }

    async searchMotif(search: string, page = 1): Promise<Pagination> {
        return await Axios.get(`${this.path}?page=${page}&search=${search}`);
    }

    async createMotif(motif: any): Promise<any>{
        return await Axios.post(this.path, motif);
    }

    async deleteMotif(relation_id: Number, motif_id: Number, relation: string): Promise<any>{
        return await Axios.delete(`${this.path}/${relation_id}/${motif_id}/${relation}`);
    }
}

export default new MotifTeleconsultation;