import { Notification } from './../_types/notification';
import Axios from '../api';
import { Pagination } from '@/_types/pagination';

 class NotificationService {
    path: string;
    constructor(){
        this.path ="/notifications";
    }

    async getNotifications(page = 1, page_size = 5): Promise<Pagination> {
        return await Axios.get(`${this.path}?page_size=${page_size}&page=${page}`);
    };

    async getNotification(id: number): Promise<Notification> {
        return await Axios.get(`${this.path}/${id}`);
    }

    async readallNotifications(): Promise<Notification> {
        return await Axios.get(`${this.path}/read_all`);
    }

    async updatedNotification(id: number, notification: Notification): Promise<Notification> {
        return await Axios.patch(`${this.path}/${id}`, notification);
    }

    async deletedNotification(id: number): Promise<Notification> {
        return await Axios.delete(`${this.path}/${id}`);
    }

}

export default new NotificationService();