<template lang="">
    <div>
        <div>
            <div v-if="!examenData" class="mb-3">
                <span>chargement en cours...</span>
            </div>
            <div class="mb-3">
                <label class="typo__label">Examen Complementaire<span class="red">*</span></label>
               <!--  <VueMultiselect v-model="state.description" deselect-label="Can't remove this value" 
                    track-by="id" label="fr_description" placeholder="S�lectionneer l'examen" :options="examenData" 
                    :searchable="false" :allow-empty="false" @search-change="asyncFindExamenComplementaire"
                    :multiple="true" :close-on-select="true" :preserve-search="true">
                    <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.type }}</strong></template>
                </VueMultiselect> -->

                <VueMultiselect v-model="state.description" tag-placeholder="Ajouter un motif" :multiple="true" :close-on-select="true" :options="examenData ?? []" placeholder="S�lectionner ou saissiser un motif" label="fr_description" track-by="id" @search-change="asyncFindExamenComplementaire" />
                <li v-if="v$.description.$error" class="red list-none">
                    {{ "s�lectionner l'eamen complementaire" }}
                </li>
            </div>
        </div>
    </div>
</template>
<script setup>
import VueMultiselect from 'vue-multiselect';

import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
    state: {},
    v$: {}
})
const store = useStore();
const examenData = computed(() => store.state.examen.examen?.data.data)
onMounted(() => {
    store.dispatch("examen/fetchExamen");
})

const asyncFindExamenComplementaire = (query) => {
    store.dispatch("examen/searchExamens", {search: query, page:  1}).then(() => {
    });
}
</script>