import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

import etablissementService from "@/_services/etablissementService";

import { Pagination } from "@/_types";

@Module({namespaced: true})
export default class EtablissementModule extends VuexModule
{
    etablissements: Pagination | null = null;

    //getters etablissements
    get gEtablissements(){
        return this.etablissements;
    }

    //mutations etablissements
    @Mutation
    mEtabllissements(etablissements: Pagination){
        this.etablissements = etablissements;
    }

    //actions etablissements
    @Action
    async fetchEtablissements(paginate = {page: 1}){
        await etablissementService.getEtablissements(paginate.page)
           .then((res: Pagination) => {
             this.context.commit('mEtabllissements', res.data.data)
           }).catch((error: any) => {
            console.log(error);
           });
    }

    @Action
    async searchEtablissements(element = {search: "", page: 1}){
        await etablissementService.searchEtablissement(element.search, element.page)
           .then((res: Pagination) => {
             this.context.commit('mEtabllissements', res.data.data)
           }).catch((error: any) => {
            console.log(error);
           });
    }
}