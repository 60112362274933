<template lang="">
    <div>
        <div v-if="!examenData" class="mb-3">
            <span>chargement en cours...</span>
        </div>
        <div class="mb-3">
            <label class="typo__label">Examen Clinique<span class="red">*</span></label>
           <!--  <VueMultiselect v-model="state.description" deselect-label="Can't remove this value" 
                track-by="id" label="fr_description" placeholder="S�lectionneer l'examen" :options="examenData" 
                :searchable="false" :allow-empty="false">
                <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.type }}</strong></template>
            </VueMultiselect> -->

            <VueMultiselect v-model="state.description" :options="examenData ?? []" :multiple="true" :preserve-search="true" :close-on-select="true" placeholder="Rechercher un examen clinique" label="fr_description" track-by="id" :loading="state.isLoadingexamenClinic" @search-change="asyncFindexamenClinic"></VueMultiselect>

            <li v-if="v$.description.$error" class="red list-none ">
                {{ "s�lectionner l'eamen clinic" }}
            </li>
        </div>
    </div>
</template>
<script setup lang="ts">
import VueMultiselect from 'vue-multiselect';

import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
    state: {},
    v$: {}
})


const store = useStore();

const examenData = computed(() => store.state.examen_clinics.examen_clinic?.data.data)

const asyncFindexamenClinic = (query: string) => {
    store.dispatch("examen_clinics/searchExamenClinics", {search: query, page:  1});
}

onMounted(() => {
    store.dispatch("examen_clinics/getExamen_clinic");
})
</script>