import Axios from '../api';
import { Pagination } from '@/_types/pagination';

class ExamenClinicService
{
    path: string;

    constructor(){
        this.path = '/examen_cliniques';
    }

    async getExamen_clinics(page = 1): Promise<Pagination>{
        return await Axios.get(`${this.path}?page=${page}`);
    }

    async searchExamen(search: string, page = 1): Promise<Pagination> {
        return await Axios.get(`${this.path}?page=${page}&search=${search}`);
    }

    async createExamen_clinic(examen_clinic: any): Promise<any>{
        return await Axios.post(this.path, examen_clinic);
    }

    async deleteExamen_clinic(relation_id: Number, examen_clinic_id: Number, relation: string): Promise<any>{
        return await Axios.delete(`${this.path}/${relation_id}/${examen_clinic_id}/${relation}`);
    }
}

export default new ExamenClinicService;