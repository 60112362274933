<template lang="">
    <div class="mt-5">
        <Modal v-model="dialogVisible" title="Affichage de l'alerte" bgcolor="#172b4d" colorTitle="white" notification="true" width="lg">
            <div class="container"></div>
                <div class="d-flex justify-content-between w-100" v-if="item?.id != null">
                    <span class="m-0 text-start fs-6"> <span class=" fwb default"> Alerte par: </span>  {{item?.creator.name}}</span>
                    <span class="m-0 text-center fs-6"><span class=" fwb default">Date - Heure: </span>  {{ item?.created_at }}</span>
                    
                    <div class="circular-green"><span class="m-0 text-end fwb default fs-6">Statut:  <b-badge :variant="item?.statut.id == 3 ? 'danger': 'primary'"> {{ item?.statut.valeur }}</b-badge></span>
                    </div>
                </div>
            <div><h5 class="mt-4 title-text default mb-0 fwb">Informations de souscripteur</h5></div>
            <ul class="list-none p-0 notification-card-content mt-0" v-if="item?.id != null">
                <li class="d-flex justify-content-between w-100 mt-3">
                    <div class="d-flex flex-wrap justify-content-between w-100 mb-2">
                        <span class="m-0 text-start"><span class=" fwb default ">Nom: </span>  {{item?.creator.name}}</span>
                        <span class="m-0 text-center"><span class=" fwb default">Email: </span>  {{item?.creator.email}}</span>
                        <span class="m-0 text-end"><span class=" fwb default">Téléphone: </span> {{item?.creator.telephone}}</span>
                    </div>
                </li>
            </ul>
           
            <ul class="list-none p-0 notification-card notification-urgence bg-light plainteBox" v-if="item?.id != null">
                <div><h5 class="mt-4 fwb default title-text mb-0">Informations du patient</h5></div>
                <ul class="list-none p-0 notification-card-content mt-0" v-if="item?.id != null">
                    <li class="w-100 mt-3">
                        <div class="d-flex justify-content-between w-100 mb-3">
                            <span class="m-0  text-start fs-6"><span class="default fwb">Nom:</span> {{item?.patient.name}}</span>
                            <span class="m-0 text-center fs-6"><span class="default fwb">Email:</span> {{item?.patient.email}}</span>
                            <span class="m-0 text-end fs-6"><span class="default fwb">Sexe: </span>{{item?.patient.patient.sexe}}</span>
                        </div>
                        <div class="d-flex justify-content-between w-100 mb-3">
                            <span class="m-0 text-start fs-6"><span class="default fwb">Téléphone:</span> {{item?.patient.telephone}}</span>
                            <span class="m-0 fs-6"><span class="default fwb">Date de naissance: </span> {{ item?.patient.patient != null ? item?.patient.patient.date_de_naissance : ''  }}</span>
                            <span><div></div></span>
                        </div>
                    </li>
                    <!-- <li class="d-flex justify-content-between w-100">
                        <div>
                            <span class="m-0 default fwb text-start">Nom: </span><span class="pl-3">{{item?.patient.name}}</span>
                        </div>
                    </li> -->
                </ul>
                <ul class="list-none p-0 notification-card notification-urgence bg-light plainteBox" v-if="item?.id != null">
                    <div class="container px-4">
                        <div class="d-inline-flex circular-red px-3 my-3"><p class="m-0 py-2 fwb"><span class="">Niveau d'urgence:</span>  {{item?.niveau_urgence_id}}</p>
                        </div>
                        <div class="mt-4 pb-3 d-md-flex justify-content-md-between">
                            <div>
                                <span class="m-0 default fwb text-start mb-5">Plainte: </span>
                                <div class="pl-3">{{item?.plainte}}</div>
                            </div>
                            <div class="mt-sm-2 mt-md-0">
                                <span class="m-0 default fwb text-start mb-5" v-if="item?.audio || item?.audio1">Audio: </span>
                                    <div class="pl-3">
                                        <div v-if="item?.audio">
                                            <audio controls>
                                                <source :src="item?.audio" type="audio/mpeg">Your browser does not support the audio tag.
                                            </audio>
                                        </div>
                                        <div v-if="item?.audio1">
                                            <audio controls>
                                                <source :src="item?.audio1" type="audio/mpeg">Your browser does not support the audio tag.
                                            </audio>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        
                    </div>
                </ul>
                <div class="w-100 mt-4" v-can="'Créer une téléconsultation'">
                    <!--   <div class=""> <a href="/alertes" @click="" class="text-primary">Voir tous les alertes</a></div> -->
                    <div class=" d-flex justify-content-between">
                        <div class="d-flex justify-content-start">
                            <button @click="SelectPatient(item?.id), (alertSelect = true)" class="btn btn-dark text-white">Faire une téléconsultation</button> 
                        </div>
                    </div>
                </div>
            </ul>
                <template #footer>
                    <div class="" v-if="loadingStatus">
                        <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin"/>
                    </div>
                    <div v-if="alertSelect && !loadingStatus" class="w-100 text-center">
                        <div v-if="isSupported" class="d-flex justify-content-center mb-4">
                            <a href="#" v-if="urlModerator" @click="CopyLink(urlPatient)" class="btn btn-secondary py-2 shadow text-center"><i class="fa fa-share-alt"></i> Cliquer ici pour copier le lien patient</a>
                        </div>
                        <button class="btn btn-primary mb-3" @click="OpenTeleconsultation(item)">Continuer vers le formulaire</button>
                        <icon-call :telephone="item?.patient.telephone" @gotoTeleconsultationForm="gotoTeleconsultationForm" :urlModerator="urlModerator" v-if="urlModerator, alertSelect" />
                    </div>
                </template>
            
            </Modal>
            <ul class="list-none p-0 px-2 d-flex row-lg gap-2 justify-content-between">
                <li class="col-lg nom">Patients</li>
                <li class="col-lg nom">Plainte<i class="fa-solid fa-circle-info"></i></li>
                <li class="col-lg gravite">Urgences</li>
                <li class="col-lg d-none d-lg-block">Date</li>
                <li class="col-lg d-none d-lg-block">Status<i class="fa-solid fa-circle-info"></i></li>
                <li class="col-lg d-none d-lg-block">Action</li>
            </ul>
            <div class="" >
                <div class="rounded shadow bg-white card-active" v-for="alerte in alertes" :key="alerte.id">
                    <ul class="list-none d-flex row-lg default fwn p-0 py-3 px-2 
                    d-flex gap-2 justify-content-between">
                        <li class="col-lg d-flex align-items-center gap-1">
                            <i class="fa-solid fa-circle text-primary text-i"
                            :class="[formattedDateTime(new Date()) === formattedDateTime(alerte.created_at) ? 'd-block' : 'd-none']"
                            ></i>
                            <span class="m-0 fwb px-2">{{ alerte.patient?.name }}</span>
                        </li>
                        <li class="col-lg">
                            <span :id="`popover-target-${alerte.uuid}`"> {{ alerte.plainte.slice(0,16) }}...</span>
                            <span v-if="alerte?.audio" class="cursor"  @click="soundVoice(alerte.audio)"><i class="fa-solid fa-circle-play mx-1 cursor-pointer"></i></span> 
                            <b-popover :target="`popover-target-${alerte.uuid}`" triggers="hover" placement="top">
                                {{alerte.plainte}}
                            </b-popover>
                        </li>
                        <li class="col-lg"><b-button class="w-25" :title="alerte.niveau_urgence.description"> {{ alerte.niveau_urgence_id }}</b-button></li>
                    <!--  <li class="col-lg"><b-button v-b-tooltip.hover :title="alerte.niveau_urgence.description"> {{ alerte.niveau_urgence_id }}</b-button></li> -->
                        <li class="col-lg d-none d-lg-block">{{ moment(alerte.created_at).format("YYYY-MM-DD HH:mm") }}</li>
                        <li class="col-lg d-none d-lg-block">
                            <b-badge :variant="alerte.statut.id == 3 ? 'danger': 'primary'" :id="`popover-target-${alerte.id}`"> <i class="fa-solid fa-user-doctor mx-1" v-if="alerte.statut.valeur == 'En cours'"></i>{{ alerte.statut.valeur }}</b-badge>
                        </li>
                        <li class="col-lg d-flex justify-content-start align-items-start gap-2" v-can="'Afficher une alerte'">
                            <button @click="launchDemoModal(alerte.id), alertSelect = false" class="text-primary d-flex align-items-center bg-none border-0"><i class="fa-sharp fa-solid fa-eye"></i><span class="px-1">voir</span></button>
                        </li>
                        <b-popover :target="`popover-target-${alerte.id}`" triggers="hover" placement="top">
                            <!-- <template #title>Description statut</template> -->
                            {{alerte?.statut.description}}
                        </b-popover>
                    </ul>
                <!-- <b-collapse :id="'collapse'+alerte.uuid" class="mb-2">
                    <b-card class="border-0">
                        <li class="list-none mb-3 rounded card-text px-3 py-2 fwb" v-if="alerte.niveau_urgence.description.length > 0">
                            <b-button :title="alerte.niveau_urgence.description">Niveau d'urgence {{ alerte.niveau_urgence_id }}</b-button>
                        </li>
                        <li class="list-none mb-2 card-text default fwb">Plainte : <span class="fwn">{{ alerte.plainte }}</span></li>
                        <li class="d-block mb-2 d-lg-none text-secondary">Date émission : {{ moment(alerte.created_at).format("YYYY-MM-DD HH:mm") }}</li>
                        <li>
                        </li>
                        <p class="d-flex gap-1 d-lg-none gap-1 text-secondary m-0 float-end">
                            <li class="alert-icon del list-none"><i class="fa-solid fa-trash"></i></li>
                            <li class="alert-icon up list-none"><i class="fa-solid fa-pen-to-square"></i></li>
                        </p>
                    </b-card>
                </b-collapse> -->
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, onUpdated, computed, ref, toRefs } from 'vue';
import moment from 'moment';
import Modal from "./../../views/Admin/Teleconsultation/Modal.vue"
import { useStore } from 'vuex';
import BaseSelected from '@/components/molecules/BaseSelected.vue'
import { useRouter } from 'vue-router'
import IconCall from '@/components/icons/IconCall.vue'
import { useClipboard, usePermission } from '@vueuse/core'
import { useToast } from 'vue-toast-notification';

const $toast = useToast();
const store = useStore()
const dialogVisible = ref(false);
const router = useRouter();
const loadingStatus = computed(() => store.state.mettings.loadingStatus)

const { text, isSupported, copy } = useClipboard()

const item = computed(() => store.state.alertes.alerte);

const OpenTeleconsultation = item => {
    const id = item?.patient.id;
    const associations = "user,dossier";
    store.dispatch("patient/fetchPatient", { id, associations });
    window.location.href = "/teleconsultations/create?slug="+item?.uuid;
    /* router.push({
        name: 'AddTeleconsultation',
        query: {'slug': item?.uuid},
    }); */
    dialogVisible.value = false
}

const props = defineProps({
  alertes: {
    type: Array,
    required: true,
  },
  uuid: ""
})

const { alertes, uuid } = toRefs(props)


const launchDemoModal = uuid => {
    store.dispatch("alertes/fetchAlerte", uuid).then(() => {
        dialogVisible.value = true
    })
}


const formattedDateTime = (date) => {
    if (moment(date).isValid() && date != null) {
        return moment(date).format("DD-MM-YYYY à HH");
    } else {
        return date;
    }
}

const soundVoice = audio => {
    let audioFile = new Audio(audio);
    audioFile.play();
}

const patientSelect = ref(false)
const find = ref([])
const urlModerator = computed(() => store.state.mettings.urlModerator)
const urlPatient = computed(() => store.state.mettings.urlPatient)

function gotoTeleconsultationForm() {
    dialogVisible.value = false;
    //router.push({ name: 'AddTeleconsultation'});
    window.location.href = "/teleconsultations/create";
}

const alertSelect = ref(false)
function SelectPatient(id = 0) {
    store.dispatch('mettings/createMetting', id);
    store.dispatch('mettings/joinMetting', id);

}

const CopyLink = (urlPatient = "") => {
    copy(urlPatient)
    $toast.success('Le lien de la vidéo conférence a été copier avec succès', { position: "bottom-right" })
}
onMounted(() => {
    if(uuid?.value != undefined){
        launchDemoModal(uuid.value);
    }
})
</script>