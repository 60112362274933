<template lang="">
    <div class="row">
        <div class="row bg-white rounded shadow px-4 pb-5 mt-4 pt-1">
            <a @click="$router.go(-1)" class="back"><i class="fa fa-arrow-left pb-3 cursor-pointer" aria-hidden="true"></i></a>
            <div class="d-flex justify-content-between">
                <h4 class="fwb default"> Affichage d'une téléconsultation</h4>
                <a v-if="teleconsultation?.url" :href="teleconsultation?.url" target="_blank"><i class="fa-solid fa-circle-play mx-1 cursor-pointer fa-2xl"></i></a>
                <button type="button" class="btn text-primary fwb"
                @click="printTeleconsultation(teleconsultation?.uuid)"><i class="fa fa-print ml-2"></i> Imprimer</button>
            </div>
            <ul class="list-none p-0 notification-card-content mt-0">
            </ul>
            <div class="mt-3">
                    <div class="default">
                        <h5 class="fwb default"><img src="@/assets/icons/DetailTeleconsultation/resume.png" alt="" class="img-alert"/>Information générales</h5>
                        <div class="boxDetails bgDetails w-100">
                            <div class="row pt-3">
                                <div class="col-md-6">
                                    <p class="">
                                        <span class="fw-bold m-1">Type:</span>{{ teleconsultation?.type?.libelle }}
                                    </p>
                                    <p>
                                        <span class="fw-bold m-1">Etablissement:</span>{{ teleconsultation?.etablissements[0]?.name }}
                                    </p>
                                </div>
                                <div class="col-md-6">
                                    <p>
                                        <span class="fw-bold m-1">Date de téléconsultation:</span>{{ moment(teleconsultation?.date_heure).format("YYYY-MM-DD HH:mm") }}
                                    </p>
                                </div>
                            </div>
                            <div class="pb-3">
                                    <p class="default fwb">Liste des motifs</p>
                                    <div class="position-absolute motif start-50" v-if="loader">
                                        <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin"/>
                                    </div>
                                    <div v-if="!loader" class="px-1">
                                        <div class="w-100" v-for="(motif, index) in teleconsultation?.motifs" :key="`motif${index}`">
                                                <ul class="list-group list-group-flush list-none p-0 notification-card-content mt-0">
                                                    <li class="list-group-item">{{ motif.description }}</li>
                                                </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                <div title="CAT" class="mt-4" v-if="teleconsultation?.cat.length!=0">
                    <div class="">
                        <h5 class="fwb default"><img src="@/assets/icons/DetailTeleconsultation/advise.png" alt="" class="img-alert"/>Conduite à tenir</h5>
                        
                        <div class="boxDetails bgDetails w-100">
                            <div class="py-3 ml-2">
                                <div class="position-absolute top-50 start-50" v-if="loader">
                                    <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin"/>
                                </div>
                                <div v-if="!loader">
                                    <div >
                                        <div class="row w-100">
                                            <span class="" v-html="teleconsultation?.cat"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div title="Antécédents" class="mt-4" v-if="teleconsultation?.antededents.length!=0">
                    <div class="">
                        <h5 class="fwb default"><img src="@/assets/icons/DetailTeleconsultation/medical-records.png" alt="" class="img-alert"/>Antécédents</h5>
                        
                        <div class="boxDetails bgDetails w-100">
                            <div class="py-3 ml-2">
                                <div class="row mb-1">
                                    <span class="col-md-4 default fwb">Type</span>
                                    <span class="col-md-4 default fwb">Description</span>
                                    <span class="col-md-4 default fwb">Date</span>
                                </div>
                                <div class="position-absolute top-50 start-50" v-if="loader">
                                    <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin"/>
                                </div>
                                <div v-if="!loader">
                                    <div v-for="(antecedent, index) in teleconsultation?.antededents" :key="`antecedent${index}`">
                                    <ul class="list-group list-group-flush list-none p-0 mb-1 notification-card-content mt-0">
                                    </ul>
                                        <div class="row">
                                            <span class="col-md-4">{{ antecedent.type?.libelle }}</span>
                                            <span class="col-md-4" v-html="antecedent?.description"></span>
                                            <span class="col-md-4">{{ antecedent.date }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div title="Allergies" class="col-md-6" v-if="teleconsultation?.allergies.length!=0">
                            <div class="">
                                <h5 class="fwb default"><img src="@/assets/icons/DetailTeleconsultation/allergen.png" alt="" class="img-alert"/>Allergies</h5>
                        
                                <div class="boxDetails bgDetails w-100">
                                    <div class="py-3 ml-2">
                                        <div class="row mb-1">
                                            <span class="col-md-6 default fwb">Description</span>
                                            <span class="col-md-6 default fwb">Date</span>
                                        </div>
                                        <div class="position-absolute top-50 start-50" v-if="loader">
                                            <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin"/>
                                        </div>
                                        <div v-if="!loader">
                                            <div v-for="(allergie, index) in teleconsultation?.allergies" :key="`allergie${index}`">
                                                <ul class="list-group list-group-flush list-none ml-1 p-0 mb-1 notification-card-content mt-0">
                                                </ul>
                                                <div class="row">
                                                    <span class="col-md-6">{{ allergie?.description }}</span>
                                                    <span class="col-md-6">{{ moment(allergie?.created_at).format("YYYY-MM-DD HH:mm") }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div title="Anamnèses" class="col-md-6" v-if="teleconsultation?.anamneses.length!=0">
                        <div class="">
                            <h5 class="fwb default"><img src="@/assets/icons/DetailTeleconsultation/medical-history.png" alt="" class="img-alert"/>Anamnèses</h5>
                        
                            <div class="boxDetails bgDetails w-100">
                                <div class="py-3 ml-2">
                                    <div class="row mb-1">
                                        <span class="col-md-6 default fwb">Type</span>
                                        <span class="col-md-6 default fwb">Description</span>
                                    </div>
                                    <div class="position-absolute top-50 start-50" v-if="loader">
                                        <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin"/>
                                    </div>
                                    <div v-if="!loader">
                                        <div class="" v-for="(anamnese, index) in teleconsultation?.anamneses" :key="`anamnese${index}`">
                                            
                                            <ul class="list-group list-group-flush list-none p-0 mb-1 notification-card-content mt-0">
                                            </ul>
                                            <div class="row">
                                                <span class="col-md-6" v-html="anamnese?.fr_description"></span>
                                                <span class="col-md-6" v-html="convertJeson(anamnese.pivot.data)"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4" v-if="teleconsultation?.examen_complementaires.length!=0">
                    <div class="col-md-6">
                        <div class=" w-100">
                            <h5 class="fwb default"><img src="@/assets/icons/DetailTeleconsultation/medical-checkup.png" alt="" class="img-alert"/>Examens Complémentaires</h5>
                            <div class="boxDetails w-100">
                                <div class="pt-1 ml-2">
                                    <div class="position-absolute top-50 start-50" v-if="loader">
                                        <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin"/>
                                    </div>
                                    <div v-if="!loader" class="">
                                        <div class="d-inline-flex" v-for="(examen_complementaire, index) in teleconsultation?.examen_complementaires" :key="`examen_complementaire${index}`">
                                            <span class="bgDetails pt-2 px-2 m-1 text-uppercase">{{ examen_complementaire?.fr_description }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-2" v-if="teleconsultation?.examen_cliniques.length!=0">
                        <div class="">
                            <h5 class="fwb default"><img src="@/assets/icons/DetailTeleconsultation/diagnosis.png" alt="" class="img-alert"/> Examens cliniques</h5>
                            <div class="boxDetails w-100">
                                <div class="pb-3 pt-1 ml-2">
                                    <span class="mb-3" v-html="teleconsultation?.description_examen_clinique"></span>
                                    <div v-if="!loader" class="mt-3">
                                        <div class="d-inline-flex" v-for="(examen_clinique, index) in teleconsultation?.examen_cliniques" :key="`examen_clinique${index}`">
                                            <span class="bgDetails pt-2 px-2 m-1 text-uppercase">{{ examen_clinique?.fr_description }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" mt-4" v-if="teleconsultation?.diagnostics.length!=0">
                    <div class="">
                        <h5 class="fwb default"><img src="@/assets/icons/DetailTeleconsultation/diagnosis.png" alt="" class="img-alert"/> Diagnostics</h5>
                        <div class="boxDetails bgDetails w-100">
                            <div class="pb-3 pt-1 ml-2">
                                <div class="position-absolute top-50 start-50" v-if="loader">
                                    <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin"/>
                                </div>
                                <div>
                                    <p>
                                        <span class="fw-bold m-1 default">Description: </span> <span v-html="teleconsultation?.description_diagnostic"></span>
                                    </p>
                                </div>
                                <div class="pb-3">
                                    <p class="default fwb">Diagnostics ICD</p>
                                    <div class="row mb-1">
                                <span class="col-md-6 default fwb">Code</span>
                                <span class="col-md-6 default fwb">Nom</span>
                            </div>
                            <div class="position-absolute top-50 start-50" v-if="loader">
                                <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin"/>
                            </div>
                            <div v-if="!loader">
                                <div v-for="(diagnostic, index) in teleconsultation?.diagnostics" :key="`diagnostic${index}`">
                                    <ul class="list-group list-group-flush list-none p-0 mb-1 notification-card-content mt-0">
                                    </ul>
                                    <div class="row">
                                        <span class="col-md-6">{{ diagnostic.code_icd }}</span>
                                        <span class="col-md-6">{{ diagnostic?.name }}</span>
                                    </div>
                                </div>
                            </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-4" v-if="teleconsultation?.ordonnances.length!=0">
                    <div class="">
                        <h5 class="fwb default"><img src="@/assets/icons/DetailTeleconsultation/diagnosis.png" alt="" class="img-alert"/> Ordonnances</h5>
                        <div class="boxDetails bgDetails w-100">
                            <div class="pb-3 pt-1 ml-2">
                                <div>
                                    <p v-for="(ordonnance, index) in teleconsultation?.ordonnances" :key="'ordonnance'+index">
                                        <span class="fw-bold m-1 default">Description: </span> {{ moment(ordonnance?.date).format("YYYY-MM-DD HH:mm") }} <span v-html="ordonnance.description"></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div title="Rendez-vous" class="mt-4" v-if="teleconsultation?.rendez_vous.length!=0">
                    <div class="">
                        <h5 class="fwb default"><img src="@/assets/icons/DetailTeleconsultation/medical.png" alt="" class="img-alert"/> Rendez-vous</h5>
                        <div class="boxDetails w-100">
                            <div class="pb-3 pt-1 ml-2">
                                <div class="position-absolute top-50 start-50" v-if="loader">
                                    <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin"/>
                                </div>
                                <div v-if="!loader">
                                    <div class="row" v-for="(rdv, index) in teleconsultation?.rendez_vous" :key="`rdv${index}`">
                                        <p class="col-md-12"><span class="fw-bold my-1">Date: </span> {{ moment(rdv?.date).format("YYYY-MM-DD HH:mm") }}</p> 
                                        <p><span class="fw-bold my-1">Motif: </span><span v-html="rdv?.motifs"></span></p> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>

    </div>

</template>
<script setup>
import { useStore } from 'vuex';
import moment from "moment";
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import ExamenComplementaireModule from '../../../store/modules/examen/index';


const store = useStore();
const router = useRoute();

const teleconsultation = computed(() => store.state.teleconsultations.teleconsultation);
// Globale
const props = defineProps({
  teleconsultation: {},
})

const loader = computed(() => store.state.allergie.loadingStatus)
const lien = computed(() => store.state.teleconsultations.lien)

const printTeleconsultation = (teleconsultation_id) => {
    store.dispatch('teleconsultations/printTeleconsultation', teleconsultation_id).then(() => {
        window.open(lien.value, '_blank');
    })
}

const convertJeson = data => {
    if(data != undefined){
        return JSON.parse(data).anamnese
    }
    return ""
}

onMounted(() => {
    store.dispatch("teleconsultations/getTeleconsultation", router.params.uuid)
})


</script>