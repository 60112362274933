import { Alerte } from '@/_types/alerte';
import Axios from '../api';
import { Teleconsultation } from '@/_types/teleconsultation';
import { Pagination } from '@/_types/pagination';

 class TeleconsultationService {
    path: string;
    path2: string;
    pathrdv: string;
    constructor(){
        this.path ="/teleconsultations";
        this.path2 = "/types";
        this.pathrdv = "/rendez_vous"
    }

    async getTeleconsultations(page = 1, page_size = 10, search = ""):  Promise<Pagination> {
        return await Axios.get(`${this.path}?page_size=${page_size}&page=${page}&search=${search}`);
    };

    async getTeleconsultation(id: any): Promise<Teleconsultation> {
        return await Axios.get(`${this.path}/${id}`);
    }

    async createdTeleconsultation(teleconsultation: any): Promise<Teleconsultation> {
        return await Axios.post(this.path, teleconsultation);
    }

    async updatedTeleconsultation(id: number, teleconsultation: Teleconsultation): Promise<Teleconsultation> {
        return await Axios.patch(`${this.path}/${id}`, teleconsultation);
    }

    async getAlerte(medecin_id: number, patient_id: number): Promise<Alerte> {
        return await Axios.get(`${this.path}/create/${medecin_id}/${patient_id}`);
    }

    async getAllergies(patient_id: number): Promise<any> {
        return await Axios.get(`${this.path}/allergies/${patient_id}`);
    }

    async getAntecedents(patient_id: number): Promise<any> {
        return await Axios.get(`${this.path}/antecedents/${patient_id}`);
    }

    async deletedTeleconsultation(id: number): Promise<Teleconsultation> {
        return await Axios.delete(`${this.path}/${id}`);
    }

    async getTypesTeleconsultation(type: string | null): Promise<Pagination> {
        return await Axios.get(`${this.path2}?type=${type}`);
    }

    async getRendezVous(): Promise<any> {
        return await Axios.get(`${this.pathrdv}`);
    }

    async createRendezVous(rendez_vous: any): Promise<any> {
        return await Axios.post(`${this.pathrdv}`, rendez_vous);
    }

    async deleteRendezVous(id: number): Promise<any> {
        return await Axios.delete(`${this.pathrdv}/${id}`);
    }

    async printTeleconsultation(teleconsultation_id: number): Promise<any> {
        return await Axios.get(`${this.path}/print/${teleconsultation_id}`);
    }
}

export default new TeleconsultationService();