import { Pagination, Patient } from '@/_types';
import PatientService from "@/_services/PatientService";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import moment from "moment";
import { useToast } from 'vue-toast-notification';

@Module({
  namespaced: true
})
export default class PatientsModule extends VuexModule {

  patient: Patient | null = null;
  select: Patient | {} = {}

  antecedants: [] | null = null;

  loadingStatus: boolean = false;

  $toast = useToast();

  get gpatient()  {
    return this.patient
  }

  get selectPatient() {
    return this.select
  }

  get gAntecedants() {
    return this.antecedants
  }

  //getters loading
  get gloadingStatus(){
    return this.loadingStatus
  }

  @Mutation
  mloadingStatus(newStatus: boolean){
      this.loadingStatus = newStatus
  }


  @Mutation
  setAntecedantHandler(antecedants: any){
    this.antecedants = antecedants
  }

  @Mutation
  setPatientHandler(patient: Patient) {
    this.patient = patient
  }

  @Mutation
  setSelectPatientHandler(patient: any) {
    this.select = patient
  }


  @Action
  async fetchPatient(find = {id: "", associations: ""}) {

    await PatientService.getPatient(find.id, find.associations).then((res: Patient) => {
      this.context.commit('setSelectPatientHandler', res.data)
    }).catch((error: any) => {
      console.log("error ", error.response.data)
    })
  }

  @Action
  async searchPatient(find: ""){
    await PatientService.searchPatient(find).then((res: Patient) => {
      const patientData: Patient | any = res.data
      const searchData = patientData.data.map((item: any) => {
        return {
          date_de_naissance: item.date_de_naissance,
          sexe: item.sexe,
          age: item.age,
          nom: item.user?.nom,
          prenom: item.user?.prenom,
          name: item.user?.name,
          user_id: item.user_id,
          telephone: item.user?.telephone
        };
      })
      // console.log("res==>", searchData)
      this.context.commit('setPatientHandler', searchData)
      return true;
    }).catch((error: any) => {
      console.log("error ", error)
    })
  }

  @Action
  async fetchAntecedents() {
    await PatientService.getAntecedent().then((res: any) => {
      this.context.commit('setAntecedantHandler', res.data)
    }, err => { console.log('error', err) })
  }

  @Action
  async createAntecedent(antecedent: any) {
    this.context.commit('mloadingStatus', true)
    const antecedents = {
      description: antecedent.description,
      type_id: antecedent?.type.id,
      date: moment(antecedent.date).format('YYYY-MM-DD HH:mm'),
      dossier_medical_id: antecedent.dossier_medical_id,
      teleconsultation_id: antecedent.teleconsultation_id
    }
    await PatientService.createdAntecedent(antecedents).then((res: any) => {
      this.context.commit('mloadingStatus', false)
      let antededents = this.context.rootState.teleconsultations.teleconsultation.antededents;
      console.log("res.data", res.data)
      let teleconsultation = {...this.context.rootState.teleconsultations.teleconsultation, antededents: [res.data.data, ...antededents]};
      this.context.commit("teleconsultations/getTeleconsultationHandler", teleconsultation, { root: true });
      this.$toast.success('Antécedant créé avec succès', { position: "top-right" })
      return true //Promise.resolve(res)
    }, err => { console.log('error', err) })
  }

  @Action
  async deleteAntecedent(antecedent_id: 0){
    this.context.commit('mloadingStatus', true)
    await PatientService.deleteAntecedent(antecedent_id)
    .then((res: any) => {
      this.context.commit('mloadingStatus', false)
      let antecedents = this.context.rootState.teleconsultations.teleconsultation.antededents?.filter((item: any) => item.id != res.data.data.id);
      let teleconsultation = {...this.context.rootState.teleconsultations.teleconsultation, antededents: antecedents};
      this.context.commit("teleconsultations/getTeleconsultationHandler", teleconsultation, { root: true });
      this.$toast.success('Antécedant supprimé avec succès', { position: "top-right" })
    }, err => { 
      console.log('error', err)
      this.context.commit('mloadingStatus', false) 
    })
  }
}
