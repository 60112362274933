<template lang="">
    <div class="medicament row default">
        <div class="row bg-white rounded shadow px-4 pb-5 mt-4 pt-1">
            <div class="d-md-flex justify-content-md-between mt-4 mb-1">
                <a @click="$router.go(-1)" class="back"><i class="fa fa-arrow-left pb-3 cursor-pointer" aria-hidden="true"></i></a>
                <h5 class="fwb default">Détails d'une prescription de médicaments</h5>
                <!-- <a v-if="teleconsultation?.url" :href="teleconsultation?.url" target="_blank"><i class="fa-solid fa-circle-play mx-1 cursor-pointer fa-2xl"></i></a>-->
                <b-dropdown v-can="'Imprimer une e-prescription'">
                    <template #button-content>
                        <button type="button" class="btn text-primary fwb"><i class="fa fa-print ml-2"></i> Imprimer</button>
                    </template>
                    <b-dropdown-item href="#" @click="printPrescription(store.getOnePrescription.pdf, 'a4')">A4</b-dropdown-item>
                    <b-dropdown-item href="#" @click="printPrescription(store.getOnePrescription.pdf, 'a5')">A5</b-dropdown-item>
                    <b-dropdown-item href="#" @click="printPrescription(store.getOnePrescription.pdf, 'a6')">A6</b-dropdown-item>
                </b-dropdown>
            </div>
            <div class="d-flex justify-content-end" v-if="store.getOnePrescription?.patient?.user" v-can="'Partager une e-prescription'">
                <div class="p-2" v-can="'Partager une e-prescription'">
                    Envoyer par
                </div>
                <!-- <div class="p-2">
                    <ShareNetwork
                        network="email"
                        :url="store.getOnePrescription?.pdf"
                        :title="'Hello, consulter la prescription *#'+store.getOnePrescription?.id+'* du patient *'+store.getOnePrescription?.patient.user.name+'*'"
                        description="Cliquez sur le lien ci-dessus">
                    </ShareNetwork>
                </div> -->

                <div class="p-2" v-can="'Partager une e-prescription'">
                    <ShareNetwork
                        network="telegram"
                        :url="store.getOnePrescription?.pdf"
                        :title="'Hello, consulter la prescription *#'+store.getOnePrescription?.id+'* du patient *'+store.getOnePrescription?.patient.user.name+'*'"
                        description="Cliquez sur le lien ci-dessus">
                        <i class="fa-brands fa-telegram fa-2x" style="color: #0088cc;"></i>
                    </ShareNetwork>
                </div>
                <div class="p-2" v-can="'Partager une e-prescription'">
                    <ShareNetwork
                        network="whatsapp"
                        :url="store.getOnePrescription?.pdf"
                        :title="'Hello, consulter la prescription *#'+store.getOnePrescription?.id+'* du patient *'+store.getOnePrescription?.patient.user.name+'*'"
                        description="Cliquez sur le lien ci-dessus">
                        <i class="fa-brands fa-whatsapp fa-2x" style="color: #128C7E;"></i>
                    </ShareNetwork>
                </div>
            </div>
            <div class="d-flex mt-4" v-if="store.loading">
                <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin m-auto"/>
            </div>
            <div v-if="!store.loading">
                <div class="mt-3">
                    <fieldset class="form-group border p-2 default">
                        <legend class="default float-none w-auto px-2 h2">Documentations</legend>
                            <div class="boxDetails w-100">
                                <div class="row pt-2">
                                    <div class="col-md-6">
                                        <p class="mb-4">
                                            <span class="fw-bold my-2"><img src="@/assets/icons/DetailTeleconsultation/medical-records.png" alt="" class="img-alert"/>Etablissement :</span> <div class="pt-1" v-for="(etablissement, index) in store.getOnePrescription?.etablissements" :key="etablissement.uuid">{{etablissement?.name}}</div>
                                        </p>
                                        <p class="mb-4">
                                            <span class="fw-bold my-2"><img src="@/assets/icons/DetailTeleconsultation/medical-records.png" alt="" class="img-alert"/>Evénement Médical associé :</span> <div class="pt-1">{{store.getOnePrescription.ligne_temps?.description}}</div>
                                        </p>
                                        <p>
                                            <div>
                                                <span class="fw-bold mb-1 mt-2"><img src="@/assets/icons/DetailTeleconsultation/save-money.png" alt="" class="img-alert"/>Option de financements : </span>
                                            </div>
                                            <div class="mx-3">
                                            <div class="mt-2 d-inline-flex" v-for="finance in store.getOnePrescription.option_financements"><span class="bgDetails py-1 px-2 mx-1">
                                                    {{finance.libelle}}
                                                </span></div>
                                            </div>
                                        </p>
                                        <p class="mb-4">
                                            <span class="fw-bold my-2"><img src="@/assets/icons/DetailTeleconsultation/medical-records.png" alt="" class="img-alert"/>Date :</span> <div class="pt-1"> <span >{{moment(store.getOnePrescription?.date_heure).format('DD-MM-YYYY')}}</span></div>
                                        </p>
                                    </div>
                                    <div class="col-md-6">
                                        <p class="mb-4">
                                            <span class="fw-bold m-1 my-2"><img src="@/assets/icons/DetailTeleconsultation/medical-service.png" alt="" class="img-alert"/>Niveau d'uregnce : </span>
                                            <span class="mt-1 mx-2">{{store.getOnePrescription.niveau_urgence_id}}</span>
                                        </p>
                                        <p>
                                            <div>
                                                <span class="fw-bold mb-1 mt-2"><img src="@/assets/icons/DetailTeleconsultation/prescription.png" alt="" class="img-alert"/>Raison de la Prescription :</span>
                                            </div>
                                            <div class="mx-3">
                                            <div class="mt-2 d-inline-flex" v-for="raison in store.getOnePrescription.raison_prescriptions"><span class="bgDetails py-1 px-2 mx-1">
                                                    {{raison.libelle}}
                                                </span></div>
                                            </div>
                                        </p>
                                        <p class="mb-4">
                                            <span class="fw-bold my-2"><img src="@/assets/icons/DetailTeleconsultation/medical-records.png" alt="" class="img-alert"/>Motif :</span> <div class="pt-1"> <span v-html="store.getOnePrescription?.motif"></span></div>
                                        </p>
                                    </div>
                                </div>
                            </div>
                    </fieldset>

                    <fieldset class="form-group border p-2 mt-3">
                        <legend class="default float-none w-auto px-2 h2">Médicaments</legend>
                            <div class="boxDetails w-100">
                                <div class="row pt-2">
                                    <div class="col-md-12 table-responsive">
                                       <table class="table default">
                                        <thead>
                                            <tr>
                                                <th>N°</th><th>Médicaments</th><th>Posologies</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(medicament, index) in store.getOnePrescription.medicaments" :key="medicament.uuid">
                                                <td>{{ medicament.code }}</td>
                                                <td>{{ medicament.denomination }} <br/>
                                                    <b>Conditionnement</b> :  {{ medicament.conditionnement?.libelle }} <br>
                                                    <b>Forme médicamenteuses</b> : <span> {{ medicament.forme_pharmaceutique}}</span> <br>
                                                    <b>Relation alimentaires</b> : <span v-for="(relation_alimentaire, index) in medicament.relation_alimentaires" :key="relation_alimentaire.uuid"> {{ relation_alimentaire.libelle+', ' }}</span> <br>
                                                    <b>Voies administrations</b> : <span> {{ medicament.voie_administration_id }}</span> <br>
                                                </td>
                                                <td>{{ medicament.pivot.quantite_lors_une_prise }} {{medicament.unite_presentation?.libelle}} {{ medicament.pivot.nombre_de_prise }} fois tout les {{ medicament.pivot.nombre_de_fois }} heures pendant {{ medicament.pivot.duree_traitement }} jours
                                                    (
                                                        <span v-for="(horaire_de_prise, index) in medicament.horaire_de_prises" :key="horaire_de_prise.uuid"> {{ horaire_de_prise.libelle+', ' }}</span>
                                                    ) <span v-if="medicament.pivot.nombre_renouvelement > 0">renouvelable {{ medicament.pivot.nombre_renouvelement }} fois. <br></span>
                                                    <b>Nombre d'unité d'achat</b> : {{ medicament.pivot.nombre_unite_achat }}<br/>
                                                    <b>Intervalle entre deux prises</b> : {{ medicament.pivot.intervalle_entre_deux_prises }}<br/>
                                                </td>
                                            </tr>
                                        </tbody>
                                       </table>
                                    </div>
                                </div>
                            </div>
                    </fieldset>
                </div>

            </div>
            <div v-for="doctor in store.getOnePrescription.medecin" class="my-3">
                <span class="my-3">
                    {{doctor.name}}
                </span>
                <!-- <div>
                    <span class="">
                        Téléphone : 
                        <span class="">
                            {{doctor.user?.telephone}}
                        </span>
                    </span>
                </div> -->
                <div>
                    <span class="">
                        Numero d'ordre : 
                        <span class="">
                            {{doctor.numero_ordre}}
                        </span>
                    </span>
                </div>
                <div class="img">
                    <img :src="doctor.user?.signature" alt="">
                </div>
            </div>
            <div class="d-md-flex justify-content-md-end">
                <div class="mb-2 mt-2 mx-2" v-can="'Modifier une e-prescription'">
                    <button @click="openShow(store.getOnePrescription), SelectPatient(store.getOnePrescription.patient_id, datas)" size="sm" class="btn btn-dark px-2 fw-bold text-h5 w-100">
                        <i class="fa fa-edit px-1"></i><span class="px-1">Modifier</span>
                    </button>
                </div>
                <div class="mb-2 mt-2 mx-2" v-can="'Supprimer une e-prescription'">
                    <button  @click="deletePrescription(store.getOnePrescription.id)" size="sm" class="btn btn-outline-danger px-2 fw-bold text-h5 w-100">
                        <i class="fa fa-trash px-1"></i><span class="px-1">Supprimer</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>
<script setup>
import { useStore } from 'vuex';
import moment from "moment";
import { computed, onMounted } from 'vue';
import UserInfo from '@/components/organisms/UserInfo.vue';
import ExamenComplementaireModule from '../../../store/modules/examen/index';
import AddPrescription from '@/views/Admin/ePrescription/AddPrescription.vue';
import { usePrescription } from '../../../_storeV2/eprescription/index';
import { useRouter, useRoute } from 'vue-router';
import { inject } from 'vue';
const swal = inject('$swal');
const stor = useStore();

const datas = "user,dossier";
const store = usePrescription();
const route = useRouter();
const router = useRoute();
const prescription = computed(() => store.prescription);
// Globale
const props = defineProps({
  prescription: {},
})

const printPrescription = (prescription_pdf, format = "a4") => {
    window.open(prescription_pdf+'/'+format, '_blank');
}
function openShow(prescription) {
        // useStore.getPrescriptionId(prescription.uuid);
        route.push({
            name: 'modification-eprescription' ,
            params: {'uuid': prescription.uuid},
        })
    }
    const deletePrescription=(item_id)=>{
        swal.fire({
        title: 'Voulez-vous vraiment supprimer ce Prescription?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Annuler',
        denyButtonText: `Supprimer`,
    })
    .then((result) => {
        if (result.isDenied) {
            store.deletePrescription(item_id).then(() => {
                store.fetchPrescriptions(1, "", 5); 
                route.push({
                    name: 'e-prescription' ,
                })
            });
        }
    })
    }
const convertJeson = data => {
    if(data != undefined){
        return JSON.parse(data).anamnese
    }
    return ""
}
function SelectPatient(id, associations) {
    stor.dispatch('patient/fetchPatient', { id: id, associations: associations })
}
onMounted(() => {
    store.getPrescriptionId(router.params.uuid);
})
</script>