<template lang="">
  <div class="py-4 bg-white px-3 rounded-1 shadow dark prescription">
    <h4 class="">Nouvelle Prescription</h4>
    <hr />
    <form action="" class="prescription-form">
      <multi-steps-form
        :steps="steps"
        :validations="validation"
        :submitForm="submitForm"
        :AvoidSubmitForm="AvoidSubmitForm"
        :Status="Status"
      >
        <template #step1>
          <div>
            <AdministrativeData :state="state" :v$="v$" />
          </div>
        </template>
        <template #step2>
          <div class="row">
            <div class="col-md-5 d-block my-4">
              <label class="default">Etablissement</label>
              <div v-if="dataEtablissement">
                <VueMultiselect
                  v-model="state.etablissement.id"
                  :options="dataEtablissement"
                  :close-on-select="true"
                  :preserve-search="true"
                  placeholder="Rechercher l'établissement de consultation"
                  label="name"
                  track-by="id"
                  :loading="state.isLoadingEtablissement"
                  @search-change="asyncFindEtablissement"
                  :preselect-first="false"
                >
                </VueMultiselect>
                <!-- <small v-if="v$.$error && v$.etablissement.$invalid" class="red list-none ">
                                {{ "L'établissement est obligatoire" }} <br>
                            </small> -->
              </div>
            </div>
          </div>
          <div class="mb-2">
            <Medicament :medicaments="state.medicament" />
          </div>
          <div class="mb-4">
            <span class="text-danger font-weight-bold">*</span
            ><label class="typo__label default">Motif Prescription :</label>
            <div>
              <Editor
                api-key="mm3cmjvl9px5ksezprcy72un7g456ii25fpje4frqemgkrqn"
                :init="{
                  height: 300,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | removeformat | help',
                }"
                v-model="state.motif.id"
                :value="state.motif.id"
                @onEditorChange="onEditorInput"
              />

              <!-- <small v-if="v$.description.$error" class="red list-none ">
                                {{ "entrer une description" }}
                            </small> -->
            </div>
          </div>
          <div class="border-bottom-dashed prescription-content row">
            <div class="mb-2 w-50">
              <label class="typo__label"
                >Date et heure<span class="red">*</span></label
              >
              <Datepicker
                auto-apply
                :close-on-auto-apply="false"
                :max-date="new Date()"
                locale="fr"
                :is-24="true"
                v-model="state.date_heure"
                placeholder="date et heure"
              />
              <!-- <small v-if="v$.date_heure.$error" class="red list-none">
                                {{ "entrer une date" }}
                            </small> -->
            </div>
          </div>
        </template>
      </multi-steps-form>
    </form>
  </div>
</template>

<script setup>
import AdministrativeData from "@/components/organisms/AdministrativeData.vue";
import VueMultiselect from "vue-multiselect";
import * as ECT from "@whoicd/icd11ect";
import { useVuelidate } from "@vuelidate/core";
import "@whoicd/icd11ect/style.css";
import Modal from "@/views/Admin/Teleconsultation/Modal.vue";
import Medicament from "@/views/Admin/ePrescription/AddMedicament.vue";
import Editor from "@tinymce/tinymce-vue";
import _ from "lodash";
import { useStore } from "vuex";
import { storeToRefs } from "pinia";
import { required } from "@vuelidate/validators";
import PrescriptionService from "@/_services/PrescriptionService";
import MultiStepsForm from "@/components/form/MultiStepsForm.vue";
import { useRouter, useRoute } from "vue-router";
// import { Prescription } from '../../../_storeV2/eprescription/index';
import { ref, reactive, inject, onMounted, computed, watch } from "vue";
import { usePrescription } from "@/_storeV2/eprescription";
import EtablissementModule from "../../../store/modules/etablissement/index";

import Datepicker from "@vuepic/vue-datepicker";

const swal = inject("$swal");
const loading = computed(() => store.getLoading);
const store = usePrescription();
const xstore = useStore();
const router = useRouter();
const route = useRoute();
// Globale
const props = defineProps({
  state: {},
  v$: {},
});
const Status = ref(false);
const steps = reactive([
  { title: "Documentation", slotName: "step1" },
  { title: "Données", slotName: "step2" },
  // { title: 'Examen(s)', slotName: 'step3' },
]);
const PlainteRules = computed(() => {
  return {
    description: { required },
    type: { required },
    date: { required },
  };
});

const dataEtablissement = computed(() => {
  return xstore.state.etablissement.etablissements?.data.map((item) => {
    return {
      id: item.id,
      name: item.name,
    };
  });
});
const onEditorInput = (content) => {
  console.log("===========================", content);
  state.value.motif.id = content;
};
const first = ref(true);
const second = ref(false);
const third = ref(false);
const doc = ref(true);
const elements = ref(false);
const dialogVisible = ref(false);
const height = ref(200);
const value = ref([]);
const maladie = ref([]);
const dataExamenPush = ref([]);
let data = ref([]);
const categorie = ref("");
const horaire = ref("");
const Presentation = ref("");
const evenement = ref("");

const getFinance = computed(() => {
  return store.getOnePrescription.option_financements || [];
});
const getRaison = computed(() => {
  return store.getOnePrescription.raison_prescriptions || [];
});
const getUrgence = computed(() => {
  return store.getOnePrescription.niveau_urgence || [];
});
const getLigne = computed(() => {
  return store.getOnePrescription.ligne_temps || [];
});
const getMotif = computed(() => {
  return store.getOnePrescription.motif || [];
});
const getEtab = computed(() => {
  return store.getOnePrescription.etablissements || [];
});
const medicamentData = computed(() => {
  return store.getOnePrescription.medicaments || [];
});
const user_id = computed(() => xstore.state.users.user.id);
const medecin_id = xstore.state.users.user.id;
const patient_id = xstore.state.patient.select.user_id;

let option_financement = ref([]);
let etablissements = ref({});
let option_financemt = ref({});
let raison_presc = ref({});
let niveau_urg = ref({});
let ligne_temps = ref({});
let raison = ref({});

const motifs = computed(() => {
  raison.value = { id: getMotif.value };
  return raison.value;
});
const ligneTemp = computed(() => {
  ligne_temps.value = {
    id: { id: getLigne.value.id, description: getLigne.value.description },
  };
  return ligne_temps.value;
});
const urgence = computed(() => {
  niveau_urg.value = {
    id: {
      id: getUrgence.value.id,
      valeur: getUrgence.value.valeur,
      description: getUrgence.value.description,
    },
  };
  return niveau_urg.value;
});
const financement = computed(() => {
  getFinance.value.forEach((item) => {
    option_financement.value.push(item.id);
    option_financemt.value = { id: option_financement.value };
  });
  return option_financemt.value;
});
const etablissemt = computed(() => {
  getEtab.value.forEach((item) => {
    etablissements.value = { id: { id: item.id, name: item.name } };
  });
  return etablissements.value;
});
const prescription = computed(() => {
  getRaison.value.forEach((item) => {
    raison_presc.value = { id: { id: item.id, libelle: item.libelle } };
  });
  return raison_presc.value;
});
let DataMedicament = [];
DataMedicament = computed(() => {
  return JSON.parse(localStorage.getItem("medicament"))?.map((item) => {
    return {
      denomination: item.medicament.denomination,
      forme_medicamenteuse: item.medicament.forme_pharmaceutique,
      voie_administration: item.medicament.voies_administration,
      dure: item.duree_traitement,
    };
  });
});
const AvoidSubmitForm = () => {
  swal
    .fire({
      title: "Voulez-vous vraiment quitter cette page?",
      text: "Vous n'avez pas terminé la saisie de ce formulaire",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#32325d",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, quitter",
      cancelButtonText: "Non, annuler",
    })
    .then((result) => {
      if (result.isConfirmed) {
        router.go(-1);
      }
    });
};

//truncate word
const truncate = (word, maxLength) => {
  if (word.length > maxLength) {
    return word.slice(1, maxLength) + "...";
  }
  return word;
};

const state = reactive({
  creator: user_id,
  niveau_urgence: urgence,
  etablissement: etablissemt,
  option_financement: financement,
  raison_prescription: prescription,
  evenement_medical: ligneTemp,
  motif: {
    id: "",
  } /* motifs */,
  medicament: medicamentData,
  isLoadingEtablissement: false,
  medecin_id: medecin_id,
  patient_id: patient_id,
  date_heure: new Date(),
});
// console.log(state.creator);

// console.log("patient_id", state.patient_id)

const rules = reactive({
  niveau_urgence: { required },
  option_financement: { required },
  raison_prescription: { required },
  evenement_medical: { required },
  etablissement: { required },
  motif: { required },
  // typesTc: { required },
  // teleconsultation_id: { required },
  // description: { required },
  // date: { required },
  // medecin_id: medecin_id,
  // patient_id: patient_id,
});
const v$ = useVuelidate(rules, state);
v$.value.$touch();
const validation = computed(() => {
  if (
    !v$.value.niveau_urgence.$error &&
    !v$.value.option_financement.$error &&
    !v$.value.raison_prescription.$error &&
    !v$.value.evenement_medical.$error
  ) {
    return true;
  } else {
    return false;
  }
});
const submitForm = () => {
  v$.value.$validate();
  if (
    v$.value.motif.$model.id.length != null &&
    v$.value.evenement_medical.$model.id != null &&
    v$.value.option_financement.$model.id.length != 0 &&
    v$.value.raison_prescription.$model.id.length != 0 &&
    v$.value.niveau_urgence.$model.id.length != 0 &&
    v$.value.etablissement.$model.id != null
  ) {
    console.log("Ok", v$.value);
    // showAlert.value = false
    if (route.params.uuid) {
      store.updatePrescription(route.params.uuid, state);
      Status.value = store.loading;
    } else {
      store.createPrescription(state);
      Status.value = store.loading;
    }
  } else {
    console.log("NotOk", v$.value);

    // showAlert.value = true
    setTimeout(() => {
      // showAlert.value = false;
    }, 10000);
    Status.value = false;
  }
};

//Get form elements
const formeMedicamenteuse = ref([]);
const formeMedicamenteuses = async () => {
  await PrescriptionService.getFormeMedicamenteuses()
    .then((response) => {
      formeMedicamenteuse.value = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
const Conditionnement = ref([]);
const Conditionnements = async () => {
  await PrescriptionService.getConditionnements()
    .then((response) => {
      Conditionnement.value = response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
const CategorieMedicamenteuse = ref([]);
const CategorieMedicamenteuses = async () => {
  await PrescriptionService.getCategorieMedicamenteuses()
    .then((response) => {
      CategorieMedicamenteuse.value = response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
const VoieAdministration = ref([]);
const VoieAdministrations = async () => {
  await PrescriptionService.getVoieAdministration()
    .then((response) => {
      VoieAdministration.value = response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
const UnitePresentation = ref([]);
const UnitePresentations = async () => {
  await PrescriptionService.getUnitePresentations()
    .then((response) => {
      UnitePresentation.value = response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const HoraireDePrise = ref([]);
const HoraireDePrises = async () => {
  await PrescriptionService.getHoraireDePrises()
    .then((response) => {
      HoraireDePrise.value = response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
const RelationAlimentaire = ref([]);
const RelationAlimentaires = async () => {
  await PrescriptionService.getRelationAlimentaires()
    .then((response) => {
      RelationAlimentaire.value = response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
const asyncFindEtablissement = (query) => {
  state.isLoadingEtablissement = true;
  xstore
    .dispatch("etablissement/searchEtablissements", { search: query, page: 1 })
    .then(() => {
      state.isLoadingEtablissement = false;
    });
};

onMounted(() => {
  // formeMedicamenteuses();
  Conditionnements();
  CategorieMedicamenteuses();
  // VoieAdministrations();
  UnitePresentations();
  HoraireDePrises();
  RelationAlimentaires();
  if (localStorage.getItem("medicament")) {
    data.value = JSON.parse(localStorage.getItem("medicament")) || [];
    // state.medicament = JSON.parse(localStorage.getItem('medicament')) || [];
  }
  xstore.dispatch("etablissement/fetchEtablissements");
  if (route.params.uuid !== undefined) {
    store.getPrescriptionId(route.params.uuid);
  }
});
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
