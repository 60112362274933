import { Alerte } from './../../../_types/alerte';
import { Notification } from './../../../_types/notification';

import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { Pagination } from "@/_types"
import NotificationService from '@/_services/NotificationService';
import VuexPersistence from 'vuex-persist'


@Module({
  namespaced: true
})
export default class NotificationModules extends VuexModule {

  notifications : Array<Notification> = [];
  notification : Notification | null = null;
  paginations : Pagination | null = null;
  alertes : Array<Alerte> = [];
  count_notifications : number = 0;

  loadingStatus: boolean = true;


  get g_notifications()  {
    return this.paginations?.data.data
  }
  get countNotifications(){
    return this.notifications.length + this.alertes.length
  }
  get g_alertes()  {
    return this.alertes
  }
  get g_notification()  {
    return this.notification
  }
  get gpaginations()  {
    return this.paginations
  }

  //getters loading
  get gloadingStatus(){
    return this.loadingStatus
  }

//-------------------------- Mutation teleconsultation
  @Mutation
  getCountNotificationsHandler(count_notifications: number) {
    this.count_notifications += count_notifications
  }


  @Mutation
  getNotificationsHandler(paginations: Pagination) {
    this.paginations = paginations
  }

  @Mutation
  getNotifications(notifications: Array<Notification>) {
    this.notifications = notifications
    this.count_notifications = notifications.length
  }

  @Mutation
  getNotificationHandler(notification: Notification) {
    this.notification = notification
  }

  @Mutation
  getAlertesHandler(alerte: Alerte) {
    this.alertes = [alerte, ...this.alertes]
    this.count_notifications = this.notifications.length + this.alertes.length
  }
  @Mutation
  deleteAlertesHandler(id: number) {
    this.alertes = this.alertes.filter(item => item.id != id);
    this.notifications = this.notifications.filter((item: any) => item.data?.id != id);
    this.count_notifications = this.notifications.length + this.alertes.length
  }
  @Mutation
  readNotificationsHandler() {
    this.alertes = [];
    this.notifications = [];
    this.count_notifications = this.notifications.length + this.alertes.length
  }

  @Mutation
  deleteNotificationHandler(notification: Notification) {
    this.count_notifications--;
    this.notifications = this.notifications.filter(item => item.id != notification.id);
  }

  @Mutation
  mloadingStatus(newStatus: boolean){
    this.loadingStatus = newStatus
  }



//---------------------------------------- Actions notification
  @Action
  fetchNotifications(paginate = {page: 1, page_size: 10}) {
    this.context.commit('mloadingStatus', true)

    NotificationService.getNotifications(paginate.page, paginate.page_size).then((res: Pagination) => {
      this.context.commit('getNotificationsHandler', res.data)
      this.context.commit('mloadingStatus', false)
    }).catch((error: any) => {
      console.log("error ", error.response.data)
    })

  }

  @Action
  fetchNotification(id: number) {
    this.context.commit('mloadingStatus', true)
    NotificationService.getNotification(id).then((res: any) => {
      this.context.commit('getNotificationHandler', res.data)
      this.context.commit('mloadingStatus', false)
    }).catch((error: any) => {
      console.log("error ", error.response.data)
    })
  }

  @Action
  readallNotifications() {
    this.context.commit('mloadingStatus', true)
    NotificationService.readallNotifications().then((res: any) => {
      this.context.commit('readNotificationsHandler')
      this.context.commit('mloadingStatus', false)
    }).catch((error: any) => {
      console.log("error ", error.response.data)
    })
  }


  @Action
  setAlertes(alerte: any) {
    this.context.commit('getAlertesHandler', alerte)
  }

  @Action
  setCountNotifications(notification: number) {
    this.context.commit('getCountNotificationsHandler', notification)
  }

  @Action
  setNotifications(notifications: any) {
    this.context.commit('getNotifications', notifications)
  }

  @Action
  deleteAlertes(id: number) {
    this.context.commit('deleteAlertesHandler', id)
  }


}
