<template lang="">
    <div class="default" v-can="'Listing des e-prescriptions'">
        <div v-can="'Créer une e-prescription'">
            <patient-select btn_text="Nouvelle prescription" routeName="/prescription/create"/>
        </div>
        <!-- <router-link to="/prescription/create" type="button" 
            class="btn btn-primary p-2 text-h5 fw-bold teleconsultation-btn d-flex align-items-center">
            <i class="fa-solid fa-plus"></i> <span class="px-1">Nouvelle Prescription</span>
        </router-link> -->
        <div class="py-md-4 d-md-flex justify-content-between w-100 pt-4 pb-3">
            <div class="mt-3 mr-md-auto">
                    <h4 class="text-h2 fw-bold">Listing des prescriptions médicaments</h4>
            </div>
            <div class="d-md-flex align-items-end justify-content-end float-md-end">
                <ul class="list-none p-0 d-md-flex align-items-end mr-0 pr-0 gap-2">
                    <!-- <div class="d-flex align-items-end justify-content-between gap-1">
                        <li class="">
                            <select class="form-select select-height mt-2 mt-md-0" v-model="statut_id" aria-label="Default select example" @change="changeStatut($event)">
                                <option value="">Tout</option>
                                <option> statut.valeur </option>
                            </select>
                        </li>
                    </div> -->
                    <li class="mt-2 mt-md-0"><input type="text" v-model="search" @keyup="searchPresc($event)" class="form-control" placeholder="rechercher par patient"></li>
                </ul>
            </div>
        </div>
        <div class="d-flex mt-4" v-if="useStorePrescription.loading">
                <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin m-auto"/>
        </div>
        <div class="" v-if="!useStorePrescription.loading">
                <div class="bg-transparent d-none d-md-block">
                    <div class="rounded taille mb--3 align-items-center row px-1">
                        <div class="col-md">
                            <ul class="list-none px-2 py-2 row-lg table-header gap-2 justify-content-between d-flex align-items-center">
                                <li class="col-lg nom">Nom patient</li>
                                <li class="col-lg">Motif prescription</li>
                                <li class="col-lg-3 d-none d-lg-block"> Option Financement</li>
                                <li class="col-lg-2 nom">Date</li>
                            </ul>
                        </div>
                        <div class="col-md-1">
                            <ul class="list-none px-2 py-2 row-lg table-header gap-2 justify-content-between d-flex align-items-center">
                                <li class="col-lg d-none d-lg-block">Action</li>
                            </ul>
                        </div>
                    </div>
                    <!-- <div class="row justify-content-center my-4 pr-2" role="status">
                        <div class="loading"></div>
                    </div> -->
                    <div class="rounded bg-white mb-1 align-items-center row px-1" v-for="item in useStorePrescription.getPrescription.data" :key="item.id">
                        <div @click="openShow(item), SelectPatient(item.patient_id, datas)" @mouseleave="mouse = false" @mouseover="mouse = true" :class="mouse? 'col-md cursor-pointer hoverTd':'col-md cursor-pointer'">
                            <ul class="list-none d-flex row-lg default fwn px-2 py-3 mb-2 gap-2 justify-content-between align-items-center" id="modalTC">
                                <!--  card-active <li class="col-lg "><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></li> -->
                                <li class="col-lg d-flex align-items-center gap-1">
                                    <span class="m-0 fwb"> {{item.patient?.user?.name}}  </span>
                                </li>
                                <!-- <li class="col-lg-1 d-md-flex">
                                    <span class="fwb px-4">{{item?.niveau_urgence_id}} </span> 
                                </li> -->
                                <li class="col-lg d-md-flex align-items-center">
                                    <span class="default fwb cursor-pointer d-flex justify-content-between" :id="`popover-4-${item.id}`"> <span v-html="truncate(item.motif,40)"></span></span>
                                </li>
                                <li class="col-lg-3 d-md-flex ">
                                        <span class="w-100 default fwb cursor-pointer d-flex" v-if="item.option_financements[0]"><span>{{truncate(item.option_financements[0]?.libelle, 20)}}</span> 
                                            <span><i class="fa fa-circle-info px-1" :id="`popover-2-${item.id}`"></i></span>
                                        </span>
                                        <b-popover :target="`popover-2-${item.id}`" placement="right" triggers="hover">
                                            <div class="" v-for="finance in item.option_financements">
                                                <b-badge> {{truncate(finance.libelle, 20)}}</b-badge>
                                            </div>
                                        </b-popover>
                                <!-- <span v-for="finance in item.option_financements">{{finance.libelle}}</span> -->
                                </li>
                                <li class="col-lg-2 d-flex align-items-center px-1">{{moment(item?.date_heure).format("DD-MM-YYYY") }}</li>
                            </ul>
                        </div>
                        <div class="col-md-1">
                            <div class="container-fluid w-100">
                            <li class="d-flex align-items-center w-100 mx-auto px-auto">
                                <!-- <span @click="openShow(item)" size="sm" class="border-right text-primary cursor-pointer border-5 pr-2">
                                   
                                    <i class="fa fa-eye px-1"></i>
                                </span> -->
                                <span v-can="'Modifier une e-prescription'" class="cursor-pointer w-100" size="sm" @click="openShow(item, true), SelectPatient(item.patient_id, datas)">
                                    <i class="fa fa-edit px-2" aria-hidden="true"></i>
                                </span>
                                <span v-can="'Supprimer une e-prescription'" @click="deletePrescription(item.id)" class="text-danger w-100">
                                        <i class="fa fa-trash cursor-pointer px-2"></i>
                                </span>
                            </li></div>
                        </div>
                    </div>
                </div>
            
                <!-- Mobile version -->
                <div class="shadow d-block d-md-none pt--2">
                    <div class="row justify-content-center my-4 pr-2" role="status" v-if="!useStorePrescription.getPrescription.data">
                        <div class="loading"></div>
                    </div>
                    <div  v-for="item in useStorePrescription.getPrescription.data" :key="item.id" class="list-group list-group-flush">
                        <div class="d-flex flex-row justify-content-between w-100 p-1 my-1 list-group-item" v-b-toggle="'collapse'+item.id">
                            <div class="" >
                                <span for="" class="my-2 text-h5 fw-semibold">Nom patient:</span>
                                <h6 class=" colorGrey text-uppercase pt-1 text-h5">{{item.patient?.user?.name}} </h6>
                            </div>
                            <div class="when-closed">
                                <i class="fa fa-angle-down px-2 text-dark text-h5"></i>
                            </div>
                            <div class="when-open">
                                <i class="fa fa-angle-right px-2 text-dark text-h5 "></i>
                            </div>
                        </div>          
                        <b-collapse :id="'collapse'+item.id" class="mb-2 w-100">
                                <div class="container-fluid w-100">
                                        <div class="row py-1 list-none w-100">
                                            <li class="list-none mb-3 rounded card-text urgence-3 px-1 py-2 fwb">Niveau d'urgence : {{item?.niveau_urgence_id}} </li>
                                            <li  v-if="item.option_financements[0]" class="list-none mb-2 card-text default" v-b-toggle="'collapse-2'+item.id"><span  v-if="item.option_financements[0]" class="font-weight-bold fwb">Option financement</span> : <span class="w-100 default cursor-pointer d-flex justify-content-between" :id="`popover-5-${item.id}`">
                                                <span >{{item.option_financements[0]?.libelle}}</span> 
                                                <span><i class="fa fa-circle-info"></i></span></span>
                                                <b-collapse :id="'collapse-2'+item.id" placement="right" triggers="hover">
                                                    <div class="" v-for="finance in item.option_financements">
                                                        <b-badge> {{truncate(finance.libelle, 10)}} </b-badge>
                                                    </div>
                                                </b-collapse>
                                            </li>
                                            <li v-if="item.raison_prescriptions[0]" class="list-none mb-2 card-text default" v-b-toggle="'collapse-3'+item.id"><span class="font-weight-bold fwb">Raison prescription</span> : 
                                                <span class="w-100 default cursor-pointer d-flex justify-content-between" :id="`popover-6-${item.id}`"><span >{{item.raison_prescriptions[0]?.libelle}}</span> <span><i class="fa fa-circle-info"></i></span>
                                                </span>
                                                <b-collapse :id="'collapse-3'+item.id">
                                                    <div class="" v-for="raison in item.raison_prescriptions">
                                                        <b-badge>{{raison.libelle}}</b-badge>
                                                    </div>
                                                </b-collapse>
                                            </li>
                                            <li class="list-none mb-2 default"><span class="row">
                                                <span class="font-weight-bold fwb col-3">Motif :</span>  
                                                    <span v-html="truncate(item.motif,45)" class="col-9 motifpresc"></span>
                                                </span></li>
                                            
                                            <li class="list-none mb-2 default"><span class="font-weight-bold card-text default fwb">Date</span> : {{moment(item?.date_heure).format("DD-MM-YYYY") }}</li>
                                            <!-- <div class="d-flex justify-content-between d-lg-none mx-0 mb-1 mt-2 w-100">
                                                <li class="alert-icon del list-none text-danger"><i class="cursor-pointer fas fa-trash"></i></li>
                                                <li class="alert-icon up list-none text-dark"><i class="fas fa-edit"></i></li>
                                            </div> -->
                                        </div>
                                        
                                        <div class="d-flex mb-2 justify-content-center mt-2 w-100" v-can="'Afficher une alerte'">
                                            <button v-can="'Afficher une e-prescription'" @click="openShow(item), SelectPatient(item.patient_id, datas)" size="sm" class="btn btn-primary px-2 fw-bold text-h5 w-100">
                                                <i class="fa fa-eye px-1"></i><span class="px-1">Voir plus de details</span>
                                            </button>
                                        </div>
                                        <div class="d-flex mb-2 justify-content-center mt-2 w-100">
                                            <button v-can="'Modifier une e-prescription'" @click="openShow(item, true), SelectPatient(item.patient_id, datas)" size="sm" class="btn btn-outline-dark px-2 fw-bold text-h5 w-100">
                                                <i class="fa fa-edit px-1"></i><span class="px-1">Modifier</span>
                                            </button>
                                        </div>
                                        <div class="d-flex mb-2 justify-content-center mt-2 w-100">
                                            <button v-can="'Supprimer une e-prescription'" @click="deletePrescription(item.id)" size="sm" class="btn btn-outline-danger px-2 fw-bold text-h5 w-100">
                                                <i class="fa fa-trash px-1"></i><span class="px-1">Supprimer</span>
                                            </button>
                                        </div>
                                </div>
                        </b-collapse>
                    </div>
                </div> 

            <div class="table-responsive" v-if="!loading">
                <div class="container">
                    <div v-if="loading" class="container my-3 d-flex justify-content-center">
                        <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin"/>
                    </div><br />
            </div>
                    <nav aria-label="Page navigation example" v-if="useStorePrescription.getPrescription.data">
                        <ul class="pagination justify-content-end" id="pagination-demo">
                            <!-- {{changePage()}} -->
                            <li :class="`page-item ${useStorePrescription.getPrescription.current_page == useStorePrescription.getPrescription.from ? 'disabled' : ''}`"><a class="page-link" href="#" @click="previous(useStorePrescription.getPrescription.current_page)" tabindex="-1" aria-disabled="true"> <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">Previous</span></a>
                            </li>
                            <li :class="`page-item ${useStorePrescription.getPrescription.current_page == n ? 'active text-danger' : ''}`" v-for="n in pages" @click="changePage(n)"><a class="page-link" href="#">{{ n }}</a></li>
                            <li class="page-item">
                                <li :class="`page-item ${useStorePrescription.getPrescription.current_page == useStorePrescription.getPrescription.last_page ? 'disabled' : ''}`"><a class="page-link" href="#" @click="next(useStorePrescription.getPrescription.current_page)" tabindex="-1" aria-disabled="true"> <span aria-hidden="true">&raquo;</span>
                                    <span class="sr-only">Next</span></a>
                                </li>
                            </li>
                        </ul>
                    </nav>
              </div>
            </div>
    </div>
</template>
<script setup>
import { defineComponent } from 'vue'
import PatientSelect from '@/components/organisms/PatientSelect.vue'
import { computed, ref, reactive,onMounted } from 'vue';
import AddPrescription from '@/views/Admin/ePrescription/AddPrescription.vue';
import { useRouter, useRoute } from 'vue-router';
// import { usePrescription } from '@/_storeV2/prescription'
import { onBeforeMount } from 'vue'
import { usePrescription } from '../../../_storeV2/eprescription/index';
import moment from "moment";
import { inject } from 'vue';
import { useStore } from 'vuex';
    // const searchData = reactive([
    //     }
    const search = ref('');
    const store = useStore();

    const datas = "user,dossier";
    
    const swal = inject('$swal');
    const router = useRouter();
    const route = useRoute();
    const statut_id=ref('');
    const page_size = ref(5);
    const mouse = ref(false);
    function truncate(str = "", n){
            return (str?.length > n) ? str.slice(0, n-1) + '...' : str;
        }
    function getAnalyses(page = 1, search = "", size = 5, statut_id = "") {
            let args = {
                page: page,
                search: search,
                size: size,
                statut_id: statut_id
            };
            console.log(args);
            useStorePrescription.fetchPrescriptions();
        }
    const changePage = page => {
        useStorePrescription.fetchPrescriptions({ page: page, page_size: page_size.value });
    }

    const previous = page => {
        page--;
        useStorePrescription.fetchPrescriptions( { page: page, page_size: page_size.value });
    }

    const next = page => {
        page++;
        useStorePrescription.fetchPrescriptions( { page: page, page_size: page_size.value });
    }

    const changeSize = () => {
        useStorePrescription.fetchPrescriptions({ page: 1, page_size: page_size.value });
    }
    const changeStatut=(event)=>{
        useStorePrescription.fetchPrescriptions(1, "", 5, event.target.value);
        }
    function openShow(prescription, edition=false) {
        // useStorePrescription.getPrescriptionId(prescription.uuid);
        router.push({
            name: edition ? 'modification-eprescription' : 'eprescription-affichage',
            params: {'uuid': prescription.uuid},
        })
    }

    const pages= computed(() =>{
                let numShown = 3;   // This sets the number of page tabs
                if (useStorePrescription.getPrescription.last_page!= undefined)
                    {
                    numShown = Math.min(numShown, useStorePrescription.getPrescription.last_page);
                    let first = useStorePrescription.getPrescription.current_page - Math.floor(numShown / 2);
                    first = Math.max(first, 1);
                    first = Math.min(first, useStorePrescription.getPrescription?.last_page - numShown + 1);
                    // console.log([...Array(numShown)]);
                    return [...Array(numShown)].map((k,i) => i + first);
                }
            })
    const deletePrescription=(item_id)=>{
        swal.fire({
        title: 'Voulez-vous vraiment supprimer ce Prescription?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Annuler',
        denyButtonText: `Supprimer`,
    })
    .then((result) => {
        if (result.isDenied) {
            useStorePrescription.deletePrescription(item_id).then(() => {
                useStorePrescription.fetchPrescriptions(1, "", 5); 
            });
        }
    })
    }
    const searchPresc = event => {
        search.value = event.target.value;
        if(event.target.value.length >= 3){
            useStorePrescription.fetchPrescriptions({ page: 1, page_size: page_size.value, search: event.target.value });
        }
        if(event.target.value.length == 0){
            useStorePrescription.fetchPrescriptions({ page: 1, page_size: page_size.value, search: search.value });
        }
    }

    const paginations = computed(() => useStorePrescription.getPrescription)
    const prescriptions = ref([])
    const useStorePrescription = usePrescription()

    function SelectPatient(id, associations) {
        store.dispatch('patient/fetchPatient', { id: id, associations: associations })
    }
onMounted(() => {
    useStorePrescription.fetchPrescriptions();
})
</script>